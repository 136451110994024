import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import DownArrow from "../DownArrow/DownArrow";
import UpArrow from "../UpArrow/UpArrow";
import "./InputDropdown.scss";

const InputDropdown = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const showDropdownHandler = () => {
    if (props.disabled) {
      return;
    }
    setShowDropdown((prevState) => !prevState);
  };

  const itemSelectHandler = (item) => {
    if (props.disabled) {
      return;
    }
    if (!props?.isMultiSelect) {
      setShowDropdown(false);
    }
    props.onClick(item);
  };

  useEffect(() => {
    if (props.hideDropdown) {
      setShowDropdown(false);
    }
  }, [props.hideDropdown]);

  const showSelectedItem = () => {
    let returnVal = "Select";
    if (props.isMultiSelect) {
      if (props.selectedItem.length > 0) {
        returnVal = props.selectedItem.join(", ");
      }
    } else {
      if (props.selectedItem) {
        returnVal = props.selectedItem;
      }
    }

    return returnVal;
  };

  const checkIsSelected = (currentItem) => {
    const found = props.selectedItem.find((item) => item === currentItem.label);
    if (found) return true;
    return false;
  };

  return (
    <div className="input-dropdown">
      {props.label && (
        <span className="input-dropdown__label">{props.label}</span>
      )}
      <div
        className={`input-dropdown__box ${
          props.error ? "input-dropdown__box--error" : ""
        } ${showDropdown ? "input-dropdown__box--visible" : ""}`}
      >
        <div
          onClick={showDropdownHandler}
          className={`input-dropdown__box__selected ${
            props.disabled ? "input-dropdown__box--disabled" : ""
          }`}
        >
          <span>{showSelectedItem()}</span>
          <div className="arrow-icon">
            {showDropdown ? <UpArrow /> : <DownArrow />}
          </div>
        </div>
        <ul
          className={`input-dropdown__box__list ${
            props.items.length > 3
              ? "input-dropdown__box__list--scrollable"
              : ""
          } ${showDropdown ? "input-dropdown__box__list--visible" : ""}`}
        >
          <div
            className={`items-list ${
              props.items.length > 3 ? "items-list--scrollable" : ""
            }`}
          >
            {props.items.map((item, index) => (
              // Send whole item as value to the handler, so that parent can store label as well as value
              <li
                onClick={() => itemSelectHandler(item)}
                key={index}
                className={`${props.isMultiSelect ? "item-with-icon" : ""}`}
              >
                {item.label}{" "}
                {props.isMultiSelect && checkIsSelected(item) ? (
                  <i className="icon-tick" style={{ color: "#ff6600" }} />
                ) : (
                  ""
                )}
              </li>
            ))}
          </div>
          {props.ctaBtn && (
            <div className="input-dropdown__box__new">
              <span onClick={props.ctaOnClick}>{props.ctaBtnText}</span>
            </div>
          )}
        </ul>
      </div>
      {props.caption && (
        <span
          className={`input-dropdown__caption ${
            props.error ? "input-dropdown__caption--error" : ""
          }`}
        >
          {props.caption}
        </span>
      )}
    </div>
  );
};

InputDropdown.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  items: PropTypes.array.isRequired,
  caption: PropTypes.string,
  ctaBtn: PropTypes.bool,
  ctaBtnText: PropTypes.string,
  isMultiSelect: PropTypes.bool,
};

export default InputDropdown;
