import toast from "react-hot-toast";
import { createAsyncThunk } from "@reduxjs/toolkit";
import publicAxios from "axios";

import axios from "../../../axios";
import { generateErrorMsg } from "../../../utils/errorMsgGenerator";

// GET user data
export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/me`,
        {
          headers: { Authorization: `Bearer ${data.token}` },
        }
      );
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage);
      }
      throw errorMessage;
    }
  }
);

// UPDATE user data
export const updateUser = createAsyncThunk("user/updateUser", async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/user`,
      data.userData,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    toast.success("Profile Updated Successfully.");
    return response.data.data.result;
  } catch (error) {
    let errorMessage = generateErrorMsg(error);
    if (error.response.status !== 401) {
      toast.error(errorMessage, { id: "updateUser" });
    }
    throw errorMessage;
  }
});

// CREATE new user
export const userSignup = createAsyncThunk("user/signup", async (data) => {
  try {
    await publicAxios.post(
      `${process.env.REACT_APP_API_URL}/auth/signup`,
      data
    );
  } catch (error) {
    let errorMessage = generateErrorMsg(error);
    toast.error(errorMessage, { id: "userSignup" });
    throw errorMessage;
  }
});

// VERIFY user email
export const userVerifyEmail = createAsyncThunk(
  "user/verifyEmail",
  async (data) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user/verify/email/token/${data.token}`
      );
      toast.success("Email verified successfully.");
      return data.token;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

// LOGIN user
export const userLogin = createAsyncThunk("user/login", async (data) => {
  try {
    const response = await publicAxios.post(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      data
    );
    localStorage.setItem("token", response.data.data.result);
    return response.data.data.result;
  } catch (error) {
    let errorMessage = generateErrorMsg(error);
    toast.error(errorMessage, { id: "userLogin" });
    throw errorMessage;
  }
});

// GENERATE Email verification link after social login if email is missing
export const userSocialEmailVerifyLink = createAsyncThunk(
  "user/userSocialEmailVerifyLink",
  async (data) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/send/verify/email/link`,
        {
          email: data.email,
          socialId: data.socialId,
          firstName: data.firstName,
          lastName: data.lastName,
          companyName: data.companyName,
        }
      );
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      toast.error(errorMessage);
      throw errorMessage;
    }
  }
);

// VERIFY social email
export const userSocialEmailVerify = createAsyncThunk(
  "user/userSocialEmailVerify",
  async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/verify/social/email/token/${data.token}`
      );
      console.log(response);
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      toast.error(errorMessage);
      throw errorMessage;
    }
  }
);

// RENEW token
export const userRenewToken = createAsyncThunk(
  "user/renewToken",
  async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/renew`,
        { headers: { Authorization: `Bearer ${data.token}` } }
      );
      localStorage.setItem("token", response.data.data.result);
      toast.success("Token renewed successfully!");
      return response.data.data.result;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

// FORGOT PASSWORD user (Generate password reset link)
export const userForgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/email/${data.email}/reset-password-link`
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

// RESET PASSWORD user
export const userResetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user/me/reset-password`,
        { newPassword: data.newPassword },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      toast.success("Password changed successfully!");
      return data.token;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      toast.error(errorMessage, { id: "resetPassword" });
      throw errorMessage;
    }
  }
);

// EXCHANGE temporary token
export const userExchangeTempToken = createAsyncThunk(
  "user/exchangeTempToken",
  async (data) => {
    try {
      const response = await publicAxios.get(
        `${process.env.REACT_APP_API_URL}/auth/exchange-temporary-token/${data.tempToken}`
      );
      return response.data.data.result;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

// CHANGE newUser flag to false
export const userNewToOld = createAsyncThunk(
  "user/userNewToOld",
  async (data) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user/old`,
        {},
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      toast.error(errorMessage);
      throw errorMessage;
    }
  }
);
