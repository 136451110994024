import React from "react";

import Header from "../components/Header/Header";
import Wrapper from "../components/Wrapper/Wrapper";
import UserProfile from "../components/UserProfile/UserProfile";

const Profile = () => {
  return (
    <>
      <Header label="Profile" />
      <Wrapper>
        <UserProfile />
      </Wrapper>
    </>
  );
};

export default Profile;
