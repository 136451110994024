import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import validator from "validator";

import UserLogin from "../components/UserLogin/UserLogin";
import { userLogin } from "../store/features/user/userActions";
import Spinner from "../components/Spinner/Spinner";
import { decodeJwtToken } from "../utils/decodeJwt";

const Login = () => {
  const hasSignedUp = localStorage.getItem("hasSignedUp");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isRedirected = searchParams.get("isRedirected");
  const user = useSelector((state) => state.user);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  useEffect(() => {
    if (user.token) {
      const redirectUrl = localStorage.getItem("redirectURL");
      if (redirectUrl) {
        navigate(redirectUrl);
        localStorage.removeItem("redirectURL");
      } else {
        navigate("/dashboard");
      }
    } else {
      if (isRedirected) {
        return;
      } else if (!hasSignedUp) {
        navigate("/signup");
      }
    }
  }, [user.token, navigate, dispatch, hasSignedUp, isRedirected]);

  const forgotPasswordHandler = (email) => {
    if (email.trim().length > 0) {
      navigate(`/login/forgot?email=${email}`);
    } else {
      navigate("/login/forgot");
    }
  };

  const signInHandler = async (e, email, password) => {
    e.preventDefault();
    // Email Validation
    if (!validator.isEmail(email)) {
      setIsValidEmail(false, { allow_ip_domain: true });
      return;
    }

    // Password Validation
    if (password.trim().length < 8) {
      setIsValidPassword(false);
      return;
    }

    dispatch(userLogin({ email, password }))
      .unwrap()
      .then((response) => {
        const decodedJwt = decodeJwtToken(response);
        localStorage.setItem("hasSignedUp", 1);
        if (decodedJwt.newUser) {
          window.location.href = "https://www.awakish.com/accounts-thank-you/";
        } else {
          const redirectUrl = localStorage.getItem("redirectURL");
          if (redirectUrl) {
            navigate(redirectUrl);
            localStorage.removeItem("redirectURL");
          } else {
            navigate("/dashboard");
          }
        }
      });
  };

  const thirdPartyLogin = (providerName) => {
    let url = `${process.env.REACT_APP_API_URL}/auth/${providerName}`;
    window.location.href = url;
  };

  return (
    <>
      {user.loading && <Spinner />}
      <UserLogin
        onForgotPassword={forgotPasswordHandler}
        onSignIn={signInHandler}
        isValidEmail={isValidEmail}
        isValidPassword={isValidPassword}
        setIsValidEmail={setIsValidEmail}
        setIsValidPassword={setIsValidPassword}
        onThirdPartyLogin={thirdPartyLogin}
      />
    </>
  );
};

export default Login;
