import React, { useState, useEffect, useCallback } from "react";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

import "./NewResource.scss";
import RadioButton from "../RadioButton/RadioButton";
import InputDropdown from "../InputDropdown/InputDropdown";
import InputText from "../InputText/InputText";
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import Backdrop from "../Backdrop/Backdrop";
import CreateGroup from "../CreateGroup/CreateGroup";
import { fetchUserData } from "../../store/features/user/userActions";
import Spinner from "../Spinner/Spinner";
import {
  fetchGroups,
  createGroup,
} from "../../store/features/groupList/groupListActions";

import {
  fetchResource,
  updateResource,
  createResource,
} from "../../store/features/editResource/editResourceActions";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Alert from "../Alert/Alert";

let REGION_LABELS = {
  eastus: "East US",
  westus: "West US",
  westeurope: "West Europe",
  southeastasia: "South East Asia",
};

const NewResource = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.user);
  const groupList = useSelector((state) => state.groupList);
  const editResource = useSelector((state) => state.editResource);

  const [newGroupCreated, setNewGroupCreated] = useState(false);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [hideGroupDropdown, setHideGroupDropdown] = useState(false);

  // Form Input state
  const [resourceType, setResourceType] = useState("http-endpoint");
  const [resourceGroup, setResourceGroup] = useState("");

  const [resourceName, setResourceName] = useState("Resource 1");
  const [resourceWebsite, setResourceWebsite] = useState("https://www.");
  const [showKeyValue, setShowKeyValue] = useState(false);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [isResourceFetched, setIsResourceFetched] = useState(false);
  const [resourceSSL, setResourceSSL] = useState(false);
  const [resourceIp, setResourceIp] = useState("");
  const [port, setPort] = useState("");
  const [resourceFrequency, setResourceFrequency] = useState({
    label: "5 min",
    value: "5-min",
  });
  const [resourceSSLFrequency, setresourceSSLFrequency] = useState({
    label: "Every week",
    value: "Every-week",
  });
  const [resourceNotifications, setResourceNotifications] = useState([]);
  const [resourceMailOnErrorInterval, setResourceMailOnErrorInterval] =
    useState({ label: "60 min", value: 60 });
  const [selectedRegions, setSelectedRegions] = useState([
    {
      label: "All regions",
      value: "all",
    },
  ]);
  const [isEnabled, setIsEnabled] = useState(true);

  const [notificationError, setNotificationError] = useState([]);

  const [fieldError, setFieldError] = useState({
    resourceGroup: false,
    resourceName: false,
    resourceWebsite: false,
    key: false,
    resourceFrequency: false,
    resourceSSLFrequency: false,
    resourceNotifications: false,
    resourceIp: false,
    port: false,
    resourceMailOnErrorInterval: false,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exists, setExists] = useState(false);

  const groupSetter = useCallback(() => {
    let defaultGroup = groupList.groups.find(
      (item) => item.groupName === "Default Group"
    );
    if (defaultGroup) {
      setResourceGroup({
        id: defaultGroup.groupId,
        label: defaultGroup.groupName,
      });
    } else {
      if (groupList.groups.length) {
        let settingGroup = groupList.groups[0];
        setResourceGroup({
          id: settingGroup.groupId,
          label: settingGroup.groupName,
        });
      }
    }
  }, [groupList.groups]);

  useEffect(() => {
    /**
     * If no group exists, then create a default group
     */
    if (exists) {
      return;
    }
    if (groupList.groups.length === 0) {
      dispatch(fetchGroups({ token: user.token }))
        .unwrap()
        .then((res) => {
          if (res.length === 0) {
            dispatch(
              createGroup({
                group: {
                  name: "Default Group",
                  description:
                    "Your resources will get created in this group by default",
                },
                token: user.token,
              })
            )
              .unwrap()
              .then((r) => {
                setResourceGroup(
                  produce((draft) => {
                    return {
                      id: r?._id,
                      label: r?.name,
                    };
                  })
                );
                setExists(true);
              });
          }
        });
    }
  }, [dispatch, exists, groupList.groups.length, user.token]);

  useEffect(() => {
    if (props.isEdit) {
      return;
    }

    if (groupList.groups.length !== 0) {
      /**
       * Check if a groupId exists in the URL
       * if it exists, find the group and set it as the selected group
       */
      const urlGroupId = searchParams.get("groupId");
      if (urlGroupId && !newGroupCreated) {
        let group = groupList.groups.find(
          (item) => item.groupId === urlGroupId
        );
        setResourceGroup(
          produce((draft) => {
            return {
              id: group?.groupId,
              label: group?.groupName,
            };
          })
        );
      } else if (!urlGroupId && newGroupCreated === false) {
        /**
         * Check if a group exists with the groupId stored in lastAccesedGroup (localStorage)
         * If it exists, set it as the selected group
         */
        const lastAccessedGroup = JSON.parse(
          localStorage.getItem("lastAccessedGroup")
        );
        if (lastAccessedGroup) {
          let group = groupList.groups.find(
            (item) => item.groupId === lastAccessedGroup.id
          );
          if (group) {
            setResourceGroup({
              id: group.groupId,
              label: group.groupName,
            });
          } else {
            groupSetter();
          }
        } else {
          groupSetter();
        }
      }
    }
  }, [
    groupList.groups,
    dispatch,
    user.token,
    searchParams,
    newGroupCreated,
    props.isEdit,
    groupSetter,
  ]);

  // Add currently logged in user's email Id in notifications when the page loads
  useEffect(() => {
    if (!user.user.email) {
      dispatch(fetchUserData({ token: user.token }));
    }
    setResourceNotifications(
      produce((draft) => {
        const emailExists = draft.find((el) => el.email === user.user.email);
        if (!emailExists && user.user.email) {
          draft.push({ id: uuidv4(), email: user.user.email });
        }
      })
    );
  }, [dispatch, user.user.email, user.token, props.isEdit]);

  useEffect(() => {
    if (!resourceSSL) {
      setresourceSSLFrequency({ label: "Every week", value: "Every-week" });
      setFieldError(
        produce((draft) => {
          draft.resourceSSLFrequency = false;
        })
      );
    }
  }, [resourceSSL]);

  const addGroup = async (data) => {
    const response = await dispatch(createGroup(data)).unwrap();
    setNewGroupCreated(true);
    setResourceGroup(
      produce((draft) => {
        return {
          id: response._id,
          label: response.name,
        };
      })
    );
    setFieldError(
      produce((draft) => {
        draft.group = false;
      })
    );
    // Close the dropdown after group is created and selected
    setHideGroupDropdown(true);
    setTimeout(() => {
      setHideGroupDropdown(false);
    }, 100);
  };

  // FETCH edit resource details
  useEffect(() => {
    if (!props.isEdit) {
      return;
    }
    if (isResourceFetched) {
      return;
    }
    dispatch(fetchResource({ resourceId: props.resourceId, token: user.token }))
      .unwrap()
      .then((response) => {
        setIsResourceFetched(true);
        const notificationEmails = response.notify?.map((email) => {
          return {
            id: uuidv4(),
            email: email,
          };
        });
        const mailDeliveryInterval =
          response.mailDeliveryIntervalOnError > 60
            ? response.mailDeliveryIntervalOnError / 60 + " hrs"
            : response.mailDeliveryIntervalOnError + " min";
        setResourceType(response.resourceType);
        setResourceGroup({
          label: response.groupName,
          id: response.groupId,
        });
        setPort(response?.port);
        setResourceName(response?.name);
        setResourceWebsite(response.uri || "");
        setResourceIp(response.uri || "");
        setResourceSSL(
          response.resourceType === "ssl-certificate" ? true : false
        );
        setResourceFrequency({
          label: response?.options?.[0]?.frequency.replace("-", " "),
          value: response?.options?.[0]?.frequency,
        });
        setresourceSSLFrequency({
          label:
            response?.options?.[0]?.frequency === "a-day"
              ? "Every day"
              : "Every week",
          value:
            response?.options?.[0]?.frequency === "a-day"
              ? "Every-day"
              : "Every-week",
        });
        setResourceNotifications(notificationEmails);
        setResourceMailOnErrorInterval({
          label: mailDeliveryInterval,
          value: response.mailDeliveryIntervalOnError,
        });
        setIsEnabled(response?.isEnabled);
        if (response.hasOwnProperty("jsonCheck")) {
          setShowKeyValue(true);
          setKey(response.jsonCheck.key);
          setValue(response.jsonCheck.value);
        }
        let regions = [];
        let regionKeys = Object.keys(REGION_LABELS);
        let allRegionsExists = response.options.length === regionKeys.length;
        if (allRegionsExists) {
          regions = [
            {
              label: "All regions",
              value: "all",
            },
          ];
        } else {
          regions = response.options.map((item) => {
            return {
              label: REGION_LABELS[item.region],
              value: item.region,
            };
          });
        }
        setSelectedRegions(regions);
      });
  }, [
    props.isEdit,
    dispatch,
    groupList.groups,
    props.resourceId,
    user.token,
    isResourceFetched,
  ]);

  // Create Group Dialog Box Handlers
  const showCreateDialog = () => {
    setIsCreatingGroup(true);
  };

  const hideCreateDialog = () => {
    setIsCreatingGroup(false);
  };

  /***************
   * Form Input Handlers *
   ***************/
  const resourceTypeHandler = (event) => {
    setResourceType(event.target.value);
    if (event.target.value === "ip") {
      // Set Website to "" and SSL to "false"
      setResourceWebsite("https://www.");
      setResourceSSL(false);
      setFieldError(
        produce((draft) => {
          draft.resourceWebsite = false;
        })
      );
      setKey("");
      setValue("");
      setPort("");
      setFieldError(
        produce((draft) => {
          draft.key = false;
        })
      );
      setFieldError(
        produce((draft) => {
          draft.port = false;
        })
      );
    } else if (event.target.value === "telnet") {
      setResourceWebsite("https://www.");
      setKey("");
      setValue("");
      setResourceSSL(false);
      setFieldError(
        produce((draft) => {
          draft.resourceWebsite = false;
        })
      );
      setFieldError(
        produce((draft) => {
          draft.key = false;
        })
      );
    } else {
      if (
        event.target.value === "http-endpoint" ||
        event.target.value === "json-endpoint"
      ) {
        setKey("");
        setValue("");
        setPort("");
        setFieldError(
          produce((draft) => {
            draft.resourceWebsite = false;
          })
        );
        setFieldError(
          produce((draft) => {
            draft.key = false;
          })
        );
        setFieldError(
          produce((draft) => {
            draft.port = false;
          })
        );
      }
      // Set IP to ""
      setResourceIp("");
      setFieldError(
        produce((draft) => {
          draft.resourceIp = false;
        })
      );
    }
  };

  const resourceGroupHandler = (value) => {
    setResourceGroup(value);
    if (value) {
      setFieldError(
        produce((draft) => {
          draft.resourceGroup = false;
        })
      );
    }
  };

  const resourceNameHandler = (event) => {
    setResourceName(event.target.value);
    if (event.target.value.trim().length !== 0) {
      setFieldError(
        produce((draft) => {
          draft.resourceName = false;
        })
      );
    }
  };

  const resourceWebsiteHandler = (event) => {
    setResourceWebsite(event.target.value);
    if (event.target.value.trim().length !== 0) {
      setFieldError(
        produce((draft) => {
          draft.resourceWebsite = false;
        })
      );
    }
  };

  const keyChangeHandler = (event) => {
    setKey(event.target.value);
  };

  const valueChangeHandler = (event) => {
    setValue(event.target.value);
  };

  const resourceSSLHandler = () => {
    setResourceSSL((prevState) => !prevState);
  };

  const toggleEnableHandler = () => {
    setIsEnabled((prev) => !prev);
  };

  const resourceIpHandler = (event) => {
    setResourceIp(event.target.value);
    if (event.target.value.trim().length !== 0) {
      setFieldError(
        produce((draft) => {
          draft.resourceIp = false;
        })
      );
    }
  };

  const resourcePortHandler = (event) => {
    setPort(event.target.value);
    if (event.target.value.trim().length !== 0) {
      setFieldError(
        produce((draft) => {
          draft.port = false;
        })
      );
    }
  };

  const resourceFrequencyHandler = (value) => {
    setResourceFrequency(value);
    if (value) {
      setFieldError(
        produce((draft) => {
          draft.resourceFrequency = false;
        })
      );
    }
  };

  const resourceSSLFrequencyHandler = (value) => {
    setresourceSSLFrequency(value);
    if (value) {
      setFieldError(
        produce((draft) => {
          draft.resourceSSLFrequency = false;
        })
      );
    }
  };

  const resourceNotificationAddHandler = () => {
    setResourceNotifications(
      produce((draft) => {
        draft.push({ id: uuidv4(), email: "" });
      })
    );
  };

  const resourceNotificationChangeHandler = (event, index) => {
    event.persist();
    setResourceNotifications(
      produce((draft) => {
        draft[index] = {
          ...draft[index],
          email: event.target.value,
        };
      })
    );
    if (event.target.value.trim().length !== 0) {
      let idx = notificationError.indexOf(index);
      if (idx !== -1) {
        setNotificationError(
          produce((draft) => {
            draft.splice(idx, 1);
          })
        );
      }
    }
  };

  const resourceNotificationDeleteHandler = (index) => {
    setResourceNotifications(
      produce((draft) => {
        draft.splice(index, 1);
      })
    );
    // Error logic for showing error if email inputBox is blank
    let errorIndexes = [...notificationError];
    errorIndexes = errorIndexes.map((el) => {
      if (el > index) {
        return (el = el - 1);
      } else {
        return el;
      }
    });
    let idx = notificationError.indexOf(index);
    if (idx !== -1) {
      errorIndexes.splice(idx, 1);
    }
    setNotificationError(errorIndexes);
  };

  const resourceMailDeliveryHandler = (value) => {
    setResourceMailOnErrorInterval(value);
    if (value) {
      setFieldError(
        produce((draft) => {
          draft.resourceMailOnErrorInterval = false;
        })
      );
    }
  };

  const cancelHandler = () => {
    navigate("/dashboard");
    return;
  };

  const awakishServersTable = () => {
    return (
      <div className="server-table">
        <table>
          <thead>
            <tr>
              <th>Region</th>
              <th>IP Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>East US</td>
              <td>40.121.53.85</td>
            </tr>
            <tr>
              <td>West US</td>
              <td>23.99.86.99</td>
            </tr>
            <tr>
              <td>SouthEast Asia</td>
              <td>52.187.67.142</td>
            </tr>
            <tr>
              <td>Europe</td>
              <td>13.80.255.72</td>
            </tr>
          </tbody>
        </table>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.awakish.com/help-center/"
        >
          Learn More
        </a>
      </div>
    );
  };

  const getInfoMessage = (resourceType) => {
    const infoMessage = {
      "http-endpoint":
        "Your web server is the interface between you and the world. If it goes down, it takes along your reputation to some extent. Hence, it is very important that you monitor the important URLs for availability. To create this test, you must ensure that the website is up and running. If the website is down, or if the URL redirects to another location, you won’t be able to create this monitoring resource.",
      ip: "You can monitor any IP that can be publicly pinged. It could be a static or a dynamic IP, but it must be public. If you want to monitor a device behind a firewall or router, you must forward the request to the appropriate device.",
      "json-endpoint":
        "Sometimes, pinging and checking is not enough. If you have a website endpoint that emits a JSON with status like { status: “active” } or { status: “suspended” }, you may want to monitor the actual value of the “status” key. If the value is changed from “active” to anything else, we will alert you so you can spring back to action.",
      telnet:
        "Similar to IP monitoring, you can monitor the Ports for connectivity to various kinds of servers and services. Here, instead of simply pinging the server, we complete the handshake and connect to the destination IP and Port using our telnet client. If the telnet connection doesn’t succeed, we will alert you so you can take necessary action.",
    };

    return infoMessage[resourceType];
  };

  /***************
   * FORM SUBMIT HANDLER *
   ***************/

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    const btnType = event.nativeEvent.submitter.name;
    let error = false;

    // PERFORM VALIDATION
    const fields = [
      { label: "resourceGroup", value: resourceGroup },
      { label: "resourceName", value: resourceName },
      {
        label: `${
          resourceType === "ip" || resourceType === "telnet"
            ? "resourceIp"
            : "resourceWebsite"
        }`,
        value:
          resourceType === "ip" || resourceType === "telnet"
            ? resourceIp
            : resourceWebsite,
      },
      { label: "resourceFrequency", value: resourceFrequency },
      { label: "resourceNotifications", value: resourceNotifications },
      {
        label: "resourceMailOnErrorInterval",
        value: resourceMailOnErrorInterval,
      },
      resourceSSL
        ? { label: "resourceSSLFrequency", value: resourceSSLFrequency }
        : null,
      resourceType === "json-endpoint" ? { label: "key", value: key } : null,
    ];

    if (resourceType === "telnet") {
      fields.push({ label: "port", value: port });
      fields.push({ label: "resourceIp", value: resourceIp });
    }

    fields.forEach((field) => {
      if (
        field &&
        (!field.value ||
          field.value.length === 0 ||
          (typeof field.value === "string" && field.value.trim().length === 0))
      ) {
        error = true;
        setFieldError(
          produce((draft) => {
            draft[field.label] = true;
          })
        );
      }
    });

    // Notification Validation
    // Store the index of email input where the value is blank
    let blankEmailIndexes = [];
    resourceNotifications.forEach((notification, index) => {
      if (notification.email.trim().length === 0) {
        blankEmailIndexes.push(index);
      }
    });

    if (blankEmailIndexes.length > 0) {
      error = true;
      setNotificationError(blankEmailIndexes);
    }

    if (error) {
      toast.error("One or more fields have empty values. Kindly re-check.", {
        id: "duplicate",
      });
      return;
    }

    const notificationEmails = resourceNotifications.map((email) => {
      return email.email;
    });

    let options = [
      { frequency: resourceFrequency.value, region: "eastus" },
      { frequency: resourceFrequency.value, region: "westus" },
      { frequency: resourceFrequency.value, region: "westeurope" },
      { frequency: resourceFrequency.value, region: "southeastasia" },
    ];

    if (selectedRegions[0].value !== "all") {
      options = selectedRegions.map((region) => {
        return {
          frequency: resourceFrequency.value,
          region: region.value,
        };
      });
    }

    let data = {
      resource: {
        userId: user.user._id,
        enterpriseId: user.user.enterpriseId,
        resourceType: resourceType,
        isEnabled: true,
        uri:
          resourceType === "ip" || resourceType === "telnet"
            ? resourceIp
            : `${resourceWebsite}`,
        options,
        name: resourceName,
        notify: notificationEmails,
        groupId: resourceGroup.id,
        mailDeliveryIntervalOnError: resourceMailOnErrorInterval.value,
        localAgent: false,
        isEnabled,
      },
      token: user.token,
    };

    if (resourceType === "json-endpoint") {
      data.resource.resourceType = "http-endpoint";
      data.resource.jsonCheck = {
        key: key,
        value: value.trim(),
        checkValue: value.trim().length > 0 ? true : false,
      };
    }

    if (resourceType === "telnet") {
      data.resource.port = port;
    }

    // Send data to action
    if (props.isEdit) {
      data.resourceId = props.resourceId;
      if (resourceType === "ssl-certificate") {
        data.resource.options = [{ frequency: resourceSSLFrequency.value }];
      }
      if (showKeyValue) {
        data.resource.jsonCheck = {
          key: key,
          value: value.trim(),
          checkValue: value.trim().length > 0 ? true : false,
        };
      }
      dispatch(updateResource(data))
        .unwrap()
        .then((res) => {
          navigate("/dashboard");
        });
    } else {
      try {
        await dispatch(createResource(data)).unwrap();
        if (resourceSSL) {
          data.resource.resourceType = "ssl-certificate";
          data.resource.name = `${resourceName}-SSL`;
          data.resource.options = [{ frequency: resourceSSLFrequency.value }];
          await dispatch(createResource(data)).unwrap();
        }
        toast.success("Resource created successfully.");
        if (btnType === "btn-stay") {
          // clear all data fields; and stay on the same page
          setResourceType("http-endpoint");
          setResourceName("");
          setResourceWebsite("https://www.");
          setKey("");
          setValue("");
          setResourceSSL(false);
          setResourceIp("");
          setResourceFrequency({ label: "5 min", value: "5-min" });
          setResourceNotifications(
            produce((draft) => {
              return [{ id: uuidv4(), email: user.user.email }];
            })
          );
          setResourceMailOnErrorInterval({ label: "60 min", value: 60 });
        } else {
          // redirect to home page
          setTimeout(() => {
            navigate("/dashboard");
          }, 500);
        }
      } catch (error) {}
    }
  };

  const selectRegionHandler = (value) => {
    let newRegions = [...selectedRegions];
    // check if all regions exists
    let allRegionsFound = newRegions.find((item) => item.value === "all");
    if (allRegionsFound) {
      // check if the selected item is anything other than allRegions
      if (value.value !== "all") {
        // remove all regions from the selectedRegions and push the newly selected item
        const foundIndex = newRegions.findIndex((item) => item.value === "all");
        newRegions.splice(foundIndex, 1);
        newRegions.push(value);
      } else {
        newRegions = [];
      }
    } else {
      // if the selectedItem is all regions, remove all the other items and keep only all regions
      if (value.value === "all") {
        newRegions = [{ label: "All regions", value: "all" }];
      } else {
        let found = newRegions.find((item) => item.value === value.value);
        if (found) {
          newRegions = newRegions.filter(
            (region) => region.value !== value.value
          );
        } else {
          newRegions.push(value);
        }
      }
    }

    setSelectedRegions(newRegions);
  };

  return (
    <>
      {user.loading || groupList.loading || editResource.loading ? (
        <Spinner />
      ) : null}
      {/* Open create group dialog when user clicks on Add new */}
      {isCreatingGroup && (
        <Backdrop onClick={hideCreateDialog}>
          <CreateGroup
            onHideCreateDialog={hideCreateDialog}
            onCreateGroup={addGroup}
          />
        </Backdrop>
      )}

      {isModalOpen && (
        <Backdrop onClick={() => setIsModalOpen(false)}>
          <ConfirmationModal
            withJsx={true}
            showOkBtn={false}
            showCancelBtn={true}
            onCancel={() => setIsModalOpen(false)}
            jsx={awakishServersTable()}
            heading="Awakish Servers"
          />
        </Backdrop>
      )}

      <form
        onSubmit={formSubmitHandler}
        spellCheck={false}
        className="new-resource-form"
      >
        {!props.isEdit && (
          <div className="resource-type">
            <h3>Select Resource Type</h3>
            <div className="resource-type__options">
              <RadioButton
                name="resource-type"
                value="http-endpoint"
                label="Website Monitoring"
                checked={resourceType === "http-endpoint"}
                onChange={resourceTypeHandler}
              />
              <RadioButton
                name="resource-type"
                value="ip"
                label="IP Monitoring"
                checked={resourceType === "ip"}
                onChange={resourceTypeHandler}
              />
              <RadioButton
                name="resource-type"
                value="json-endpoint"
                label="JSON Endpoint Monitoring"
                checked={resourceType === "json-endpoint"}
                onChange={resourceTypeHandler}
              />
              <RadioButton
                name="resource-type"
                value="telnet"
                label="Port Connection Monitoring"
                checked={resourceType === "telnet"}
                onChange={resourceTypeHandler}
              />
            </div>
          </div>
        )}
        {!props.isEdit && (
          <Alert type="info" message={getInfoMessage(resourceType)} />
        )}
        {!props.isEdit && (
          <div className="group-select">
            <InputDropdown
              hideDropdown={hideGroupDropdown}
              label="Select Group"
              selectedItem={resourceGroup.label}
              onClick={resourceGroupHandler}
              items={groupList.groups.map((item) => {
                return { id: item.groupId, label: item.groupName };
              })}
              ctaBtn={true}
              ctaBtnText="+  Add New"
              ctaOnClick={showCreateDialog}
              error={fieldError.resourceGroup}
              caption={fieldError.resourceGroup ? "Group is required" : ""}
            />
          </div>
        )}

        <div className="resource-name">
          <InputText
            label="Resource Name"
            placeholder="e.g. Homepage"
            onChange={resourceNameHandler}
            value={resourceName}
            error={fieldError.resourceName}
            caption={fieldError.resourceName ? "Resource Name is required" : ""}
          />
        </div>
        {resourceType === "ip" || resourceType === "telnet" ? (
          <>
            <div className="resource-ip">
              <InputText
                label="IP Address"
                placeholder="8.8.8.8"
                value={resourceIp}
                onChange={resourceIpHandler}
                error={fieldError.resourceIp}
                caption={fieldError.resourceIp ? "IP Address is required" : ""}
              />
              {resourceType === "telnet" && (
                <InputText
                  label="&nbsp;"
                  placeholder="Port"
                  value={port}
                  onChange={resourcePortHandler}
                  error={fieldError.port}
                  caption={fieldError.port ? "Port is required" : ""}
                />
              )}
            </div>
            <span className="resource-ip__caution">
              Please ensure you allow access from{" "}
              <button type="button" onClick={() => setIsModalOpen(true)}>
                [Awakish's Servers]
              </button>{" "}
              so that we could ping the above IP address.
            </span>
          </>
        ) : (
          <div className="resource-website">
            <InputText
              label="Website Address"
              placeholder="https://"
              onChange={resourceWebsiteHandler}
              value={resourceWebsite}
              error={fieldError.resourceWebsite}
              caption={
                fieldError.resourceWebsite ? "Resource Website is required" : ""
              }
            />
            {showKeyValue || resourceType === "json-endpoint" ? (
              <div className="resource-json">
                <InputText
                  placeholder="Key"
                  value={key}
                  onChange={keyChangeHandler}
                  error={fieldError.key}
                  caption={fieldError.key ? "Key is required" : ""}
                />
                <InputText
                  placeholder="Value"
                  value={value}
                  onChange={valueChangeHandler}
                />
              </div>
            ) : null}
            {/* Hide SSL checkbox if user is editing a non-SSL resource, OR disable the checkbox if user is editing an SSL resource */}
            {props.isEdit && resourceType !== "ssl-certificate" ? null : (
              <Checkbox
                name="Resource Website SSL"
                label="Also check SSL Certificate?"
                value={resourceSSL}
                checked={resourceSSL}
                onChange={resourceSSLHandler}
                disabled={props.isEdit && resourceType === "ssl-certificate"}
              />
            )}
          </div>
        )}
        <div className="hr"></div>
        {/* Show frequency dropdown if user is editing a non-SSL resource, OR user is creating a new resource */}
        {(props.isEdit && resourceType !== "ssl-certificate") ||
        !props.isEdit ? (
          <div className="resource-frequency">
            <InputDropdown
              label="Frequency"
              selectedItem={resourceFrequency.label}
              onClick={resourceFrequencyHandler}
              items={[
                { label: "1 min", value: "1-min" },
                { label: "5 min", value: "5-min" },
              ]}
              error={fieldError.resourceFrequency}
              caption={
                fieldError.resourceFrequency ? "Frequency is required" : ""
              }
            />
          </div>
        ) : null}

        {resourceSSL && (
          <div className="ssl-frequency">
            <InputDropdown
              label="SSL Frequency"
              items={[
                { label: "Every day", value: "Every-day" },
                { label: "Every week", value: "Every-week" },
              ]}
              selectedItem={resourceSSLFrequency.label}
              onClick={resourceSSLFrequencyHandler}
              error={fieldError.resourceSSLFrequency}
              caption={
                fieldError.resourceSSLFrequency
                  ? "SSL frequency is required"
                  : ""
              }
            />
          </div>
        )}
        <div className="hr"></div>
        <div className="resource-notifications">
          <h3>Send Notification to: </h3>
          {resourceNotifications &&
            resourceNotifications.map((email, index) => (
              <div key={email.id} className="resource-notifications__input">
                <InputText
                  autoFocus={email.email === ""}
                  defaultValue={email.email}
                  placeholder="Email Address"
                  onChange={(event) =>
                    resourceNotificationChangeHandler(event, index)
                  }
                  error={notificationError.includes(index)}
                  caption={
                    notificationError.includes(index) ? "Email is required" : ""
                  }
                />
                <i
                  className="icon-trash-2"
                  onClick={() => resourceNotificationDeleteHandler(index)}
                />
              </div>
            ))}
          <button
            onClick={resourceNotificationAddHandler}
            type="button"
            className="resource-notifications__create"
          >
            Add Another Email
          </button>
        </div>
        <div className="resource-error-notification">
          <InputDropdown
            label="In case of error, send notification every:"
            items={[
              { label: "15 min", value: 15 },
              { label: "30 min", value: 30 },
              { label: "60 min", value: 60 },
              { label: "3 hrs", value: 180 },
              { label: "6 hrs", value: 360 },
              { label: "12 hrs", value: 720 },
              { label: "24 hrs", value: 1440 },
            ]}
            selectedItem={resourceMailOnErrorInterval.label}
            onClick={resourceMailDeliveryHandler}
            error={fieldError.resourceMailOnErrorInterval}
            caption={
              fieldError.resourceMailOnErrorInterval
                ? "Notification Frequency is required"
                : ""
            }
          />
        </div>
        <div className="resource-select-region">
          <InputDropdown
            label="Check the resource from these regions:"
            items={[
              { label: "All regions", value: "all" },
              { label: "East US", value: "eastus" },
              { label: "West US", value: "westus" },
              { label: "West Europe", value: "westeurope" },
              { label: "South East Asia", value: "southeastasia" },
            ]}
            selectedItem={selectedRegions.map((item) => item.label)}
            onClick={selectRegionHandler}
            isMultiSelect={true}
            error={false}
            caption={""}
          />
        </div>
        <div className="resource-select-region">
          <Checkbox
            name="Resource Enabled/Disabled"
            label={
              isEnabled
                ? "This resource is enabled and you will be alerted for downtime"
                : "This resource is disabled, and you will not be alerted for downtime"
            }
            value={isEnabled}
            checked={isEnabled}
            onChange={toggleEnableHandler}
            disabled={!props.isEdit}
          />
        </div>
        <div className="resource-submit">
          {props.isEdit ? (
            <>
              <Button
                name="btn-submit"
                type="submit"
                label="Update Resource"
                mode="primary"
                btnSize="large"
              />
            </>
          ) : (
            <Button
              name="btn-submit"
              type="submit"
              label="Save &amp; go to Dashboard"
              mode="primary"
              btnSize="large"
            />
          )}
        </div>
        {props.isEdit && (
          <div className="question-btn" style={{ marginTop: "2rem" }}>
            <p>
              Want to delete this resource?{" "}
              <button type="button" onClick={props.onDeleteResource}>
                Delete Resource
              </button>
            </p>
          </div>
        )}
        {props.isEdit ? null : (
          <>
            <div className="resource-save-and-create">
              <button name="btn-stay" type="submit">
                Save &amp; create new
              </button>
            </div>
            <div className="resource-cancel">
              <button type="button" onClick={cancelHandler}>
                Cancel &amp; go to Dashboard
              </button>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default NewResource;
