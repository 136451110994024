import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import toast, { Toaster } from "react-hot-toast";

import {
  Home,
  Login,
  Profile,
  Notifications,
  Groups,
  SingleGroup,
  AddResource,
  SingleGroupEdit,
  ResourceAnalytics,
  Forgot,
  Reset,
  Signup,
  RedirectToResource,
  OauthLogin,
} from "./pages";
import MenuContext from "./context/MenuContext";
import Navigation from "./components/Navigation/Navigation";
import UserVerifyEmail from "./components/UserVerifyEmail/UserVerifyEmail";
import ProtectedRoutes from "./ProtectedRoutes";
import { userRenewToken } from "./store/features/user/userActions";
import { decodeJwtToken } from "./utils/decodeJwt";
import { userActions } from "./store/features/user/userSlice";
import Backdrop from "./components/Backdrop/Backdrop";
import ConfirmationModal from "./components/ConfirmationModal/ConfirmationModal";
import UpdateProfileAfterLogin from "./components/UpdateProfileAfterLogin/UpdateProfileAfterLogin";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const expandMenuHandler = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const renewToken = useCallback(() => {
    try {
      dispatch(userRenewToken({ token: user.token }));
    } catch (error) {
      toast.error(error.message);
    }
  }, [user.token, dispatch]);

  // Check if token has already expired
  // if token has expired, redirect user to login page
  useEffect(() => {
    if (user.token) {
      const decoded = decodeJwtToken(user.token);
      const expDate = new Date(decoded.exp * 1000);
      let todayDate = new Date();

      if (todayDate >= expDate) {
        dispatch(userActions.logout());
        navigate("/");
      }
    }
  }, [user.token, dispatch, navigate]);

  // Auto renew token 1 min before the token expiry date
  useEffect(() => {
    if (!user.token) {
      return;
    }
    const decoded = decodeJwtToken(user.token);
    let expiryDate = new Date(decoded.exp * 1000);
    let todayDate = new Date();
    let diffInMs = expiryDate - todayDate;
    let timeoutMs = diffInMs - 60000;

    // Add logic to execute timeoutMs only if it is positive
    if (timeoutMs < 0) {
      dispatch(userActions.logout());
      navigate("/");
      return;
    }
    let clear = setTimeout(() => {
      renewToken();
    }, timeoutMs);

    return () => {
      clearTimeout(clear);
    };
  }, [user.token, renewToken, dispatch, navigate]);

  const logoutHandler = () => {
    dispatch(userActions.logout());
    setShowLogoutModal(false);
    setIsExpanded(false);
    navigate("/?isRedirected=true");
  };

  return (
    <MenuContext.Provider value={{ isExpanded, toggleMenu: expandMenuHandler }}>
      <Toaster />
      {showLogoutModal && (
        <Backdrop onClick={() => setShowLogoutModal(false)}>
          <ConfirmationModal
            heading="Logout"
            title="Are you sure you want to logout?"
            showOkBtn={true}
            showCancelBtn={true}
            onCancel={() => setShowLogoutModal(false)}
            onConfirm={logoutHandler}
          />
        </Backdrop>
      )}
      {user.token && (
        <Navigation
          onShowModal={() => setShowLogoutModal(true)}
          onToggleMenu={expandMenuHandler}
        />
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/verify-email/:tempToken" element={<UserVerifyEmail />} />
        <Route path="/login/forgot" element={<Forgot />} />
        <Route path="/reset-password/:tempToken" element={<Reset />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login/oauth/:base64" element={<OauthLogin />} />
        <Route path="/update-profile" element={<UpdateProfileAfterLogin />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/groups/:groupId" element={<SingleGroup />} />
          <Route path="/groups/:groupId/edit" element={<SingleGroupEdit />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/resource/new" element={<AddResource />} />
          <Route path="/resource/:resourceId/edit" element={<AddResource />} />
          <Route
            path="/resource/:groupName/:resourceType/:resourceName/:resourceId/details"
            element={<ResourceAnalytics />}
          />
          <Route
            path="/resource/:resourceId/details"
            element={<RedirectToResource />}
          />
        </Route>
      </Routes>
    </MenuContext.Provider>
  );
};

export default App;
