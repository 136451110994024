import axios from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { generateErrorMsg } from "../../../utils/errorMsgGenerator";

export const fetchResource = createAsyncThunk(
  "editResource/fetchResource",
  async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/resource/${data.resourceId}`,
        { headers: { Authorization: `Bearer ${data.token}` } }
      );
      return response.data.data.result;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
/*
let demoExpiryDate = new Date();
      demoExpiryDate.setDate(demoExpiryDate.getDate() + 10);
      demoExpiryDate = new Date(demoExpiryDate).toISOString();
*/

export const updateResource = createAsyncThunk(
  "editResource/updateResource",
  async (data) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/resource/${data.resourceId}`,
        data.resource,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      toast.success("Resource updated successfully.");
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage);
      }
      throw errorMessage;
    }
  }
);

export const createResource = createAsyncThunk(
  "editResource/createResource",
  async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/resources`,
        data.resource,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage, {
          id: "createResource",
        });
      }
      throw errorMessage;
    }
  }
);
