import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import "./SwitchToggle.scss";

const SwitchToggle = (props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  const switchToggleHandler = () => {
    setActive((prevState) => {
      return !prevState;
    });
    props.onClick(!active);
  };

  return (
    <button
      onClick={switchToggleHandler}
      type="button"
      className={`switch switch--${active ? "active" : "inactive"}`}
      disabled={props.disabled}
    >
      <div className="switch__box" />
    </button>
  );
};

SwitchToggle.propTypes = {
  disabled: PropTypes.bool,
};

export default SwitchToggle;
