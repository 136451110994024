export const generateErrorMsg = (error) => {
  let errorMessage = "";
  errorMessage =
    typeof error.response.data.message === "string"
      ? error.response.data.message
      : typeof error.response.data.message.message === "string"
      ? error.response.data.message.message
      : "Something went wrong. Please try again later.";
  return errorMessage;
};
