import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../components/Button/Button";
import Header from "../components/Header/Header";
import NoResource from "../components/NoResource/NoResource";
import Wrapper from "../components/Wrapper/Wrapper";
import GroupResources from "../components/GroupResources/GroupResources";
import Spinner from "../components/Spinner/Spinner";
import {
  fetchGroups,
  createGroup,
} from "../store/features/groupList/groupListActions";
import { userNewToOld } from "../store/features/user/userActions";
import { decodeJwtToken } from "../utils/decodeJwt";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resourcesCount, setResourcesCount] = useState(0);
  const user = useSelector((state) => state.user);
  const groupList = useSelector((state) => state.groupList);

  useEffect(() => {
    if (user.token) {
      const decodedJwt = decodeJwtToken(user.token);
      if (decodedJwt.newUser) {
        dispatch(userNewToOld({ token: user.token }));
      }
    }
  }, [user.token, dispatch]);

  useEffect(() => {
    dispatch(fetchGroups({ token: user.token }))
      .unwrap()
      .then((response) => {
        let count = response.reduce(
          (acc, currentElement) => currentElement.resources.length + acc,
          0
        );
        setResourcesCount(count);
        if (response.length === 0) {
          let groupData = {
            group: {
              name: "Default Group",
              description:
                "Your resources will get created in this group by default",
            },
            token: user.token,
          };
          dispatch(createGroup(groupData));
        }
      });
  }, [user.token, dispatch]);

  const addResourceHandler = () => {
    navigate("/resource/new");
  };

  return (
    <>
      <Header
        label="Dashboard"
        showBtn={groupList.groups.length > 0}
        btn={
          <Button
            mode="primary"
            label="Add Resource"
            icon={<i className="icon-Icon" />}
            iconPosition="left"
            btnSize="small"
            onClick={addResourceHandler}
            styles={{ width: "130px", font: "inherit", fontSize: "1.3rem" }}
          />
        }
      />
      <Wrapper>
        {groupList.loading && <Spinner />}
        {!groupList.loading && resourcesCount === 0 ? (
          <NoResource />
        ) : (
          groupList.groups.map(
            (group) =>
              group.resources.length > 0 && (
                <GroupResources key={group.groupId} group={group} />
              )
          )
        )}
      </Wrapper>
    </>
  );
};

export default Home;
