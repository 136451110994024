import React, { useState } from "react";

import "./UserResetPassword.scss";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import logo from "../../assets/logo.png";

const UserResetPassword = (props) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const newPasswordChangeHandler = (e) => {
    setNewPassword(e.target.value);

    if (e.target.value.trim().length > 0) {
      props.setIsValidPassword(true);
    }
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmNewPassword(e.target.value);

    if (e.target.value.trim().length > 0) {
      props.setIsValidConfirmPassword(true);
    }
  };

  return (
    <div className="user-reset__container">
      <div className="user-reset">
        <div className="user-reset__logo">
          <img src={logo} alt="Awakish Logo" />
        </div>
        <div className="user-reset__heading">
          <h1>Reset Password</h1>
        </div>
        <form
          onSubmit={(e) =>
            props.onSavePassword(e, newPassword, confirmNewPassword)
          }
          className="user-reset__form"
        >
          <div className="user-reset__form__input">
            <InputText
              type="password"
              label="New Password"
              placeholder="Enter new password"
              onChange={newPasswordChangeHandler}
              error={!props.isValidPassword}
              caption={
                props.isValidPassword
                  ? ""
                  : "Minimum length of password should be 8 characters"
              }
              value={newPassword}
            />
          </div>
          <div className="user-reset__form__input">
            <InputText
              type="password"
              label="Confirm Password"
              placeholder="Enter confirm password"
              onChange={confirmPasswordChangeHandler}
              error={!props.isValidConfirmPassword}
              caption={
                props.isValidConfirmPassword ? "" : "Passwords do not match"
              }
              value={confirmNewPassword}
            />
          </div>
          <Button
            type="submit"
            label="Save Password"
            mode="primary"
            btnSize="large"
            styles={{ maxWidth: "100%" }}
          />
        </form>
      </div>
    </div>
  );
};

export default UserResetPassword;
