import { createSlice } from "@reduxjs/toolkit";

import { createGroup, deleteGroup, fetchGroups } from "./groupListActions";

const initialState = {
  loading: false,
  groups: [],
  error: "",
};

const groupListSlice = createSlice({
  name: "groupList",
  initialState: initialState,
  extraReducers(builder) {
    // FETCH groups
    builder.addCase(fetchGroups.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.groups = action.payload;
      state.error = "";
    });
    builder.addCase(fetchGroups.rejected, (state, action) => {
      state.loading = false;
      state.groups = [];
      state.error = action.error.message;
    });
    // CREATE new group
    builder.addCase(createGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      state.loading = false;
      let group = {
        groupId: action.payload._id,
        groupName: action.payload.name,
        resources: [],
      };
      state.groups.push(group);
      state.error = "";
    });
    builder.addCase(createGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // DELETE a group
    builder.addCase(deleteGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      state.loading = false;
      let groupId = action.payload;
      let groups = state.groups.filter((group) => group.groupId !== groupId);
      state.groups = groups;
      state.error = "";

      // Update localstorage
      let localGroup = JSON.parse(localStorage.getItem("lastAccessedGroup"));
      if (localGroup && localGroup?.id === groupId) {
        localStorage.removeItem("lastAccessedGroup");
      }
    });
    builder.addCase(deleteGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default groupListSlice.reducer;
