import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { base64Decode } from "../utils/base64Decode";
import { decodeJwtToken } from "../utils/decodeJwt";
import { userActions } from "../store/features/user/userSlice";

const OauthLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { base64 } = useParams();

  useEffect(() => {
    const decodedBase64 = base64Decode(base64);

    const firstName = decodedBase64.firstName;
    const lastName = decodedBase64.lastName;

    localStorage.setItem("hasSignedUp", 1);

    if (decodedBase64?.missingEmail) {
      navigate(
        `/update-profile?firstName=${firstName || ""}&lastName=${
          lastName || ""
        }&socialId=${decodedBase64.socialId}`
      );
    } else {
      dispatch(userActions.syncLogin(decodedBase64.token));
      const decoded = decodeJwtToken(decodedBase64.token);
      if (decoded.newUser) {
        // navigate("/dashboard");
        // dispatch(userNewToOld({ token: decodedBase64.token }));
        window.location.href = "http://www.awakish.com/accounts-thank-you/";
      } else {
        const redirectUrl = localStorage.getItem("redirectURL");
        if (redirectUrl) {
          navigate(redirectUrl);
          localStorage.removeItem("redirectURL");
        } else {
          navigate("/dashboard");
        }
      }
    }
  }, [base64, navigate, dispatch]);

  return <></>;
};

export default OauthLogin;
