import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import GroupDetail from "../components/GroupDetail/GroupDetail";
import Spinner from "../components/Spinner/Spinner";

import { fetchGroups } from "../store/features/groupList/groupListActions";
import { fetchGroupDetails } from "../store/features/groupDetails/groupDetailsActions";

const SingleGroup = () => {
  const { groupId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const groupList = useSelector((state) => state.groupList);
  const groupDetails = useSelector((state) => state.groupDetails);
  const [resources, setResources] = useState([]);
  const [downCount, setDownCount] = useState(0);

  const countDownResources = (data) => {
    // Get the amount of down resources
    let down = 0;
    data.forEach((resource) => {
      let resourceTemp = 0;
      if (resource.resourceType !== "ssl-certificate") {
        if (resourceTemp > 0) {
          return;
        }
        resource.regions.forEach((region) => {
          if (resourceTemp > 0) {
            return;
          }
          if (region.data[region.latestDataIndex] === 0) {
            down++;
            resourceTemp++;
          }
        });
      }
    });
    setDownCount(down);
  };

  useEffect(() => {
    dispatch(fetchGroupDetails({ groupId, token: user.token }));
    dispatch(fetchGroups({ token: user.token }))
      .unwrap()
      .then((response) => {
        const group = response.find((item) => item.groupId === groupId);
        localStorage.setItem(
          "lastAccessedGroup",
          JSON.stringify({
            id: group.groupId,
            label: group.groupName,
          })
        );
        setResources([...group.resources]);
        countDownResources(group.resources);
      });
  }, [groupId, user.token, dispatch]);

  return (
    <>
      <Header label="Groups" />
      <Wrapper>
        {groupList.loading || groupDetails.loading ? <Spinner /> : null}
        <GroupDetail
          downCount={downCount}
          resources={resources}
          name={groupDetails.groupDetails.name}
          description={groupDetails.groupDetails.description}
          groupId={groupDetails.groupDetails._id}
        />
      </Wrapper>
    </>
  );
};

export default SingleGroup;
