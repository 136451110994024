import React from "react";

import "./NotificationDetails.scss";

const REGIONS = {
  eastus: "East US",
  westus: "West US",
  europe: "Europe",
  southeastasia: "Southeast Asia",
};

const NotificationDetails = (props) => {
  return (
    <div className="notification-details" onClick={(e) => e.stopPropagation()}>
      <div className="notification-details__detail">
        <span>Time:</span>
        <p>{props.date}</p>
      </div>
      <div className="notification-details__detail">
        <span>Website Name:</span>
        <p>{props.details["Website Name"]}</p>
      </div>
      <div className="notification-details__detail">
        <span>Emails Sent To:</span>
        <p>{props.details["Emails Sent To"]}</p>
      </div>
      <div className="notification-details__detail">
        <span>Error:</span>
        <p>{props.details.Error}</p>
      </div>
      <div className="notification-details__detail">
        <span>Region:</span>
        <p>{REGIONS[props.details.region]}</p>
      </div>
    </div>
  );
};

export default NotificationDetails;
