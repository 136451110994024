import React, { useContext } from "react";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";

import MenuContext from "../../context/MenuContext";
import "./Header.scss";

const Header = (props) => {
  const navigate = useNavigate();
  const menu = useContext(MenuContext);

  return (
    <div className={`header ${menu.isExpanded ? "header--expanded" : ""}`}>
      <i className="mobile-menu icon-menu" onClick={menu.toggleMenu} />
      <i
        className="icon-dashboard"
        style={{ cursor: "pointer", fontSize: "2rem" }}
        onClick={() => navigate("/dashboard")}
      />
      <span>{props.label}</span>
      {props.showBtn && props.btn}
    </div>
  );
};

Header.propTypes = {
  label: PropTypes.string.isRequired,
  showBtn: PropTypes.bool,
};

export default Header;
