import React from "react";
import { useNavigate } from "react-router-dom";

import noResourceImage from "../../assets/rafiki.png";
import Button from "../Button/Button";
import "./NoResource.scss";

const NoResource = () => {
  const navigate = useNavigate();

  const navigationHandler = () => {
    navigate("/resource/new");
  };

  return (
    <div className="no-resource">
      <img src={noResourceImage} alt="No Resource" />
      <p>You don't have any resources to track.</p>
      <div>
        <Button
          label="Add Resource"
          mode="primary"
          type="button"
          btnSize="large"
          styles={{
            fontSize: "1.8rem",
            marginTop: "4rem",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          onClick={navigationHandler}
        />
      </div>
    </div>
  );
};

export default NoResource;
