import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./features/user/userSlice";
import groupListReducer from "./features/groupList/groupListSlice";
import groupDetailsReducer from "./features/groupDetails/groupDetailsSlice";
import editResourceReducer from "./features/editResource/editResourceSlice";
import resourceDetailsReducer from "./features/resourceDetails/resourceDetailsSlice";
import notificationsReducer from "./features/notifications/notificationsSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    groupList: groupListReducer,
    groupDetails: groupDetailsReducer,
    editResource: editResourceReducer,
    resourceDetails: resourceDetailsReducer,
    notifications: notificationsReducer,
  },
});
