import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

import Header from "../components/Header/Header";
import Notification from "../components/Notification/Notification";
import Wrapper from "../components/Wrapper/Wrapper";
import InputDropdown from "../components/InputDropdown/InputDropdown";
import {
  getNotifications,
  deleteAllNotifications,
} from "../store/features/notifications/notificationsActions";
import Button from "../components/Button/Button";
import Spinner from "../components/Spinner/Spinner";
import Backdrop from "../components/Backdrop/Backdrop";
import ConfirmationModal from "../components/ConfirmationModal/ConfirmationModal";

const Notifications = () => {
  const dispatch = useDispatch();
  const notificationsRdx = useSelector((state) => state.notifications);
  const user = useSelector((state) => state.user);
  const [isDismissing, setIsDismissing] = useState(false);
  const [timeframe, setTimeframe] = useState({
    label: "Last 24 Hours",
    value: 24,
  });
  const [notifications, setNotifications] = useState([]);
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const fetchNotifications = useCallback(async () => {
    if (timeframe.value === "custom") {
      return;
    } else {
      let currentDate = new Date();
      let notificationsDate = new Date(
        currentDate.setHours(currentDate.getHours() - timeframe.value)
      ).toISOString();

      const res = await dispatch(
        getNotifications({ token: user.token, startTime: notificationsDate })
      ).unwrap();
      setNotifications([...res].reverse());
    }
  }, [user.token, dispatch, timeframe.value]);

  const fetchCustomDateNotifications = async () => {
    let startTime = new Date(fromDate).toISOString();
    let endTime = new Date(toDate).toISOString();
    if (endTime <= startTime) {
      toast.error("End date cannot be less than or equal to start date.", {
        id: "endDate",
      });
      return;
    }

    const res = await dispatch(
      getNotifications({ token: user.token, startTime, endTime })
    ).unwrap();
    setNotifications([...res].reverse());
  };

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const dismissNotificationsHandler = async () => {
    await dispatch(deleteAllNotifications({ token: user.token })).unwrap();
    setIsDismissing(false);
    fetchNotifications();
  };

  const timeframeSelectHandler = (value) => {
    setTimeframe(value);
    if (value.value === "custom") {
      setIsCustomSelected(true);
    } else {
      setIsCustomSelected(false);
    }
  };

  return (
    <>
      <Header label="Notifications" />
      <Wrapper showBorder={true} fillBackground={true}>
        {notificationsRdx.loading && <Spinner />}
        {isDismissing && (
          <Backdrop onClick={() => setIsDismissing(false)}>
            <ConfirmationModal
              heading="Delete all notifications"
              title="Are you sure you want to delete all notifications?"
              showOkBtn={true}
              showCancelBtn={true}
              onCancel={() => setIsDismissing(false)}
              onConfirm={dismissNotificationsHandler}
            />
          </Backdrop>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: "2rem",
          }}
        >
          <div style={{ width: "36rem" }}>
            <InputDropdown
              items={[
                { label: "Last 24 Hours", value: 24 },
                { label: "Last 2 Days", value: 48 },
                { label: "Last Week", value: 168 },
                { label: "Last Month", value: 720 },
                { label: "Custom", value: "custom" },
              ]}
              label="Select Timeframe"
              selectedItem={timeframe.label}
              onClick={timeframeSelectHandler}
            />
            {isCustomSelected && (
              <>
                <div className="custom-date">
                  <div>
                    <span>From Date</span>
                    <input
                      type="date"
                      onChange={(e) => setFromDate(e.target.value)}
                      value={fromDate}
                    />
                  </div>
                  <div>
                    <span>To Date</span>
                    <input
                      type="date"
                      onChange={(e) => setToDate(e.target.value)}
                      value={toDate}
                    />
                  </div>
                </div>
                <Button
                  styles={{ maxWidth: "10rem", marginTop: "1.5rem" }}
                  label="Fetch"
                  type="button"
                  mode="primary"
                  btnSize="small"
                  onClick={fetchCustomDateNotifications}
                />
              </>
            )}
          </div>
          <button
            type="button"
            onClick={() => setIsDismissing(true)}
            className="button-action"
          >
            Dismiss All
          </button>
        </div>
        <div style={{ padding: "2rem" }}>
          {notifications.map((notification) => (
            <Notification
              key={notification._id}
              notification={notification}
              isCustomSelected={isCustomSelected}
              onFetchAll={fetchNotifications}
              onFetchCustom={fetchCustomDateNotifications}
            />
          ))}
        </div>
      </Wrapper>
    </>
  );
};

export default Notifications;
