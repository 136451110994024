import React from "react";
import { PropTypes } from "prop-types";

import "./RadioButton.scss";

const RadioButton = (props) => {
  return (
    <label className="radio-button">
      <input
        type="radio"
        name={props.name}
        checked={props.checked}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <span className="radio-button__label">{props.label}</span>
    </label>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  disabled: false,
};

export default RadioButton;
