import { createSlice } from "@reduxjs/toolkit";
import {
  deleteAllNotifications,
  deleteSingleNotification,
  getNotifications,
} from "./notificationsActions";

const initialState = {
  loading: false,
  notifications: [],
  error: "",
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  extraReducers(builder) {
    // FETCH notifications
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
      state.loading = false;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = action.payload;
      state.error = "";
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // DELETE SINGLE notification
    builder.addCase(deleteSingleNotification.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSingleNotification.fulfilled, (state, action) => {
      state.loading = false;
      // add logic to remove that particular notification from redux state
      state.error = "";
    });
    builder.addCase(deleteSingleNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // DELETE ALL notifications
    builder.addCase(deleteAllNotifications.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAllNotifications.fulfilled, (state, action) => {
      state.loading = false;
      // add logic to remove all notifications from redux state
      state.error = "";
    });
    builder.addCase(deleteAllNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default notificationsSlice.reducer;
