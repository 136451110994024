import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./ResourceStatus.scss";
import Up from "../Up/Up";
import Down from "../Down/Down";
import { formatMs } from "../../utils/formatMs";

const REGIONS = {
  eastus: "East US",
  westus: "West US",
  southeastasia: "Southeast Asia",
  westeurope: "West Europe",
};

const ResourceStatus = (props) => {
  const navigate = useNavigate();
  const [isSSLExpired, setIsSSLExpired] = useState(false);
  const [isSSLDown, setIsSSLDown] = useState(false);
  const [sslExpiry, setSslExpiry] = useState("");

  useEffect(() => {
    if (props.resource.resourceType === "ssl-certificate") {
      const resourceCheckedDate = new Date();
      // Display "Unable to determine" if resource asCheckedOn is null
      if (!props.resource.expiryAsOnDate) {
        setIsSSLDown(true);
      } else {
        const resourceExpiryDate = new Date(props.resource.expiryAsOnDate);
        const diffTime = resourceExpiryDate - resourceCheckedDate;
        // if diffTime is negative, then display "SSL has expired {days} ago"
        if (diffTime < 0) {
          setIsSSLExpired(true);
        }
        const { days } = formatMs(diffTime);
        setSslExpiry(days);
      }
    }
  }, [
    props.resource.asCheckedOn,
    props.resource.expiryAsOnDate,
    props.resource.resourceType,
    props.resource.updatedAt,
  ]);

  const openResourceHandler = () => {
    navigate(
      `/resource/${props.groupName}/${props.resource.resourceType}/${props.resource.resourceName}/${props.resource.resourceId}/details`
    );
  };

  const editResourceHandler = (e) => {
    e.stopPropagation();
    navigate(`/resource/${props.resource.resourceId}/edit`);
    return;
  };

  const getTimeDiff = (updatedAt) => {
    const currentDate = new Date();
    const updatedDate = new Date(updatedAt || new Date());
    // get difference between the current and the updated at date
    const diff = currentDate - updatedDate;
    // convert the time difference to minutes
    let result = Math.floor(diff / 1000 / 60);
    let timeString = " mins ago";
    // Show time as "x" hrs ago
    if (result >= 60) {
      result = Math.floor(result / 60);
      timeString = " hr(s) ago";
    }
    // Show time as "Just now"
    else if (result <= 0) {
      result = "";
      timeString = "Just now";
    }
    return result + timeString;
  };

  return (
    <div
      className={`resource-status ${
        props.resource.isEnabled ? "" : "disabled"
      }`}
      onClick={() => openResourceHandler()}
    >
      <div className="resource-status__header">
        <div>
          <h3>
            {props.resource.resourceName}{" "}
            {props.resource.resourceType === "ssl-certificate"
              ? isSSLExpired
                ? "has expired"
                : "will expire in:"
              : "is:"}
          </h3>
          <i onClick={editResourceHandler} className="icon-Icon-1" />
        </div>
        <p className="resource-status__type">
          <i className="icon-cursor" />
          {props.resource.resourceType}
        </p>
      </div>
      <div className="resource-status__regions">
        {props.resource.resourceType === "ssl-certificate" ? (
          isSSLExpired ? (
            <p
              style={{
                fontSize: "2.4rem",
                fontWeight: "700",
                color: "#F83427",
              }}
            >
              {isSSLDown ? "Unable to determine" : null}
              {Math.abs(sslExpiry)} days ago
            </p>
          ) : isSSLDown ? (
            <p
              style={{ fontSize: "1.8rem", fontWeight: 700, color: "#ff6600" }}
            >
              Unable to determine. <br /> Kindly update your resource.
            </p>
          ) : (
            <p
              className="ssl-expiry"
              style={{
                color:
                  sslExpiry < 0
                    ? "#F83427"
                    : sslExpiry > 0 && sslExpiry <= 15
                    ? "#ff6600"
                    : "#23C16B",
              }}
            >
              {sslExpiry} Days
            </p>
          )
        ) : (
          props.resource.regions &&
          props.resource.regions.map((region, index) => (
            <div key={index} className="resource-status__regions__region">
              <div className="region-name">
                <div className="region-name__icon">
                  {region.data[region.latestDataIndex] === 0 ? (
                    <Down />
                  ) : (
                    <Up />
                  )}
                  <span
                    style={{
                      color:
                        region.data[region.latestDataIndex] === 0
                          ? "#F83427"
                          : "#23C16B",
                    }}
                  >
                    {region.data[region.latestDataIndex] === 0 ? "Down" : "UP"}
                  </span>
                </div>
                <span>in {REGIONS[region.regionName]}</span>
              </div>
              <div className="last-checked">
                <p>Last checked : {getTimeDiff(region?.updatedAt)}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ResourceStatus;
