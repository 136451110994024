import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import EditGroup from "../components/EditGroup/EditGroup";
import Header from "../components/Header/Header";
import Wrapper from "../components/Wrapper/Wrapper";
import Spinner from "../components/Spinner/Spinner";

import {
  fetchGroupDetails,
  fetchGroupResources,
  updateGroupDetails,
  toggleGroupResource,
  deleteGroupResource,
} from "../store/features/groupDetails/groupDetailsActions";
import ConfirmationModal from "../components/ConfirmationModal/ConfirmationModal";
import Backdrop from "../components/Backdrop/Backdrop";
import { deleteGroup } from "../store/features/groupList/groupListActions";

const SingleGroupEdit = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDeletingResource, setIsDeletingResource] = useState(false);
  const [deletingResourceId, setDeletingResourceId] = useState("");
  const [isDeletingGroup, setIsDeletingGroup] = useState(false);

  const user = useSelector((state) => state.user);
  const groupDetails = useSelector((state) => state.groupDetails);

  useEffect(() => {
    dispatch(fetchGroupDetails({ groupId, token: user.token }));
    dispatch(fetchGroupResources({ groupId, token: user.token }));
  }, [groupId, user.token, dispatch]);

  const toggleResourceStatusHandler = (status, resourceId) => {
    const data = {
      resourceId,
      status,
      token: user.token,
    };
    dispatch(toggleGroupResource(data));
  };

  const deleteResourceHandler = () => {
    dispatch(
      deleteGroupResource({ resourceId: deletingResourceId, token: user.token })
    );
    setIsDeletingResource(false);
  };

  const deleteGroupHandler = () => {
    dispatch(deleteGroup({ groupId, token: user.token }))
      .unwrap()
      .then((res) => {
        navigate("/dashboard");
      });
  };

  const formSubmitHandler = (name, description) => {
    const data = {
      group: {
        name,
        description,
      },
      groupId,
      token: user.token,
    };
    dispatch(updateGroupDetails(data))
      .unwrap()
      .then((res) => {
        navigate("/dashboard");
        return;
      });
  };

  const openDeleteModal = (resourceId) => {
    setIsDeletingResource(true);
    setDeletingResourceId(resourceId);
  };

  return (
    <>
      <Header label="Groups" />
      <Wrapper>
        {(isDeletingResource || isDeletingGroup) && (
          <Backdrop
            onClick={() =>
              isDeletingGroup
                ? setIsDeletingGroup(false)
                : setIsDeletingResource(false)
            }
          >
            <ConfirmationModal
              heading={isDeletingGroup ? "Delete Group" : "Delete Resource"}
              title={
                isDeletingGroup
                  ? "Are you sure you want to delete this group?"
                  : "Are you sure you want to delete this resource?"
              }
              showOkBtn={true}
              showCancelBtn={true}
              onCancel={() =>
                isDeletingGroup
                  ? setIsDeletingGroup(false)
                  : setIsDeletingResource(false)
              }
              onConfirm={
                isDeletingGroup ? deleteGroupHandler : deleteResourceHandler
              }
            />
          </Backdrop>
        )}
        {groupDetails.loading && <Spinner />}
        <EditGroup
          onDelete={openDeleteModal}
          toggleResourceStatus={toggleResourceStatusHandler}
          group={groupDetails.groupDetails}
          onFormSubmit={formSubmitHandler}
          onDeleteGroup={() => setIsDeletingGroup(true)}
        />
      </Wrapper>
    </>
  );
};

export default SingleGroupEdit;
