import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Backdrop from "../components/Backdrop/Backdrop";
import Header from "../components/Header/Header";
import NewResource from "../components/NewResource/NewResource";
import Wrapper from "../components/Wrapper/Wrapper";
import ConfirmationModal from "../components/ConfirmationModal/ConfirmationModal";
import { deleteGroupResource } from "../store/features/groupDetails/groupDetailsActions";

const AddResource = () => {
  const { resourceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteResourceHandler = () => {
    dispatch(deleteGroupResource({ resourceId, token: user.token }))
      .unwrap()
      .then((res) => {
        navigate("/dashboard");
      });
  };

  const location = useLocation();
  const locationPathArr = location.pathname.split("/");
  const isEdit = locationPathArr[locationPathArr.length - 1] === "edit";

  return (
    <>
      <Header label={resourceId ? "Edit Resource" : "Add Resource"} />
      <Wrapper showBorder={true} fillBackground={true}>
        {showDeleteModal && (
          <Backdrop onClick={() => setShowDeleteModal(false)}>
            <ConfirmationModal
              heading="Delete Resource"
              title="Are you sure you want to delete this resource?"
              showOkBtn={true}
              showCancelBtn={true}
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={deleteResourceHandler}
            />
          </Backdrop>
        )}
        <NewResource
          isEdit={isEdit}
          resourceId={resourceId}
          onDeleteResource={() => setShowDeleteModal(true)}
        />
      </Wrapper>
    </>
  );
};

export default AddResource;
