import { createSlice } from "@reduxjs/toolkit";
import {
  createResource,
  fetchResource,
  updateResource,
} from "./editResourceActions";

const initialState = {
  loading: false,
  resource: {},
  error: "",
};

const editResourceSlice = createSlice({
  name: "editResource",
  initialState: initialState,
  // FETCH resource
  extraReducers(builder) {
    builder.addCase(fetchResource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchResource.fulfilled, (state, action) => {
      state.loading = false;
      state.resource = action.payload;
      state.error = "";
    });
    builder.addCase(fetchResource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // UPDATE resource
    builder.addCase(updateResource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateResource.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateResource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // CREATE resource
    builder.addCase(createResource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createResource.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createResource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default editResourceSlice.reducer;
