import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Backdrop from "../components/Backdrop/Backdrop";
import CreateGroup from "../components/CreateGroup/CreateGroup";
import GroupsList from "../components/GroupsList/GroupsList";
import Header from "../components/Header/Header";
import Wrapper from "../components/Wrapper/Wrapper";
import { createGroup } from "../store/features/groupList/groupListActions";

const Groups = () => {
  const dispatch = useDispatch();
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);

  const showCreateDialog = () => {
    setIsCreatingGroup(true);
  };

  return (
    <>
      <Header
        label="Groups"
        showBtn={true}
        btn={
          <i
            onClick={showCreateDialog}
            className="icon-Icon add-group-icon"
            style={{
              fontSize: "1.9rem",
              marginLeft: "-1rem",
              cursor: "pointer",
            }}
          />
        }
      />
      <Wrapper showBorder={true} fillBackground={true}>
        {isCreatingGroup && (
          <Backdrop onClick={() => setIsCreatingGroup(false)}>
            <CreateGroup
              onCreateGroup={(data) => dispatch(createGroup(data))}
              onHideCreateDialog={() => setIsCreatingGroup(false)}
            />
          </Backdrop>
        )}
        <GroupsList onShowCreateDialog={showCreateDialog} />
      </Wrapper>
    </>
  );
};

export default Groups;
