import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./GroupsList.scss";
import Group from "../Group/Group";
import Spinner from "../Spinner/Spinner";
import {
  createGroup,
  deleteGroup,
  fetchGroups,
} from "../../store/features/groupList/groupListActions";
import Backdrop from "../Backdrop/Backdrop";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

const GroupsList = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const groupList = useSelector((state) => state.groupList);

  const [isDeletingGroup, setIsDeletingGroup] = useState(false);
  const [deletingGroupId, setDeletingGroupId] = useState("");

  useEffect(() => {
    if (groupList.groups.length > 0) {
      return;
    }
    dispatch(fetchGroups({ token: user.token }))
      .unwrap()
      .then((res) => {
        // Create a "Default Group" if all the groups are deleted.
        if (res.length === 0) {
          let groupData = {
            group: {
              name: "Default Group",
              description:
                "Your resources will get created in this group by default",
            },
            token: user.token,
          };
          dispatch(createGroup(groupData));
        }
      });
  }, [user.token, dispatch, groupList.groups.length]);

  const openDeleteModal = (e, groupId) => {
    e.stopPropagation();
    setIsDeletingGroup(true);
    setDeletingGroupId(groupId);
  };

  const deleteGroupHandler = (e) => {
    e.stopPropagation();
    dispatch(deleteGroup({ token: user.token, groupId: deletingGroupId }));
    setIsDeletingGroup(false);
  };

  return (
    <>
      {groupList.loading && <Spinner />}
      {isDeletingGroup && (
        <Backdrop onClick={() => setIsDeletingGroup(false)}>
          <ConfirmationModal
            heading="Delete Group"
            title="Are you sure you want to delete this group?"
            showOkBtn={true}
            showCancelBtn={true}
            onCancel={() => setIsDeletingGroup(false)}
            onConfirm={deleteGroupHandler}
          />
        </Backdrop>
      )}
      <div className="groups-list">
        <button
          type="button"
          className="groups-list__create"
          onClick={props.onShowCreateDialog}
        >
          Add New <br /> Group
        </button>
        {groupList.groups.map((group) => (
          <Group
            key={group.groupId}
            {...group}
            onGroupDelete={openDeleteModal}
          />
        ))}
      </div>
    </>
  );
};

export default GroupsList;
