import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import axios from "../../../axios";
import { generateErrorMsg } from "../../../utils/errorMsgGenerator";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (data) => {
    try {
      let params = `?startTime=${data.startTime}`;
      if (data.endTime) {
        params = params.concat(`&endTime=${data.endTime}`);
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications${params}`,
        { headers: { Authorization: `Bearer ${data.token}` } }
      );
      return response.data.data.results;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage);
      }
      throw errorMessage;
    }
  }
);

export const deleteSingleNotification = createAsyncThunk(
  "notifications/deleteSingle",
  async (data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/notification/archive/${data.id}`,
        {},
        { headers: { Authorization: `Bearer ${data.token}` } }
      );
      return response.data;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage, { id: "deleteNotification" });
      }
      throw errorMessage;
    }
  }
);

export const deleteAllNotifications = createAsyncThunk(
  "notifications/deleteAll",
  async (data) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/notifications`,
        { headers: { Authorization: `Bearer ${data.token}` } }
      );
      return response.data;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage, {
          id: "deleteAllNotifications",
        });
      }
      throw errorMessage;
    }
  }
);
