import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import {
  fetchUserData,
  updateUser,
  userLogin,
  userRenewToken,
  userResetPassword,
  userVerifyEmail,
  userSocialEmailVerifyLink,
  userSocialEmailVerify,
} from "./userActions";

const token = localStorage.getItem("token");

const initialState = {
  loading: false,
  user: {},
  error: "",
  token: token || "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    syncLogin: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.token = "";
      state.user = {};
    },
  },
  extraReducers(builder) {
    // LOGIN user
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload;
      state.error = "";
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.loading = false;
      state.token = "";
      state.error = action.error.message;
    });
    // GET user data
    builder.addCase(fetchUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.loading = false;
      let userData = { ...action.payload };
      let notificationEmails = userData.notificationEmails.map((email) => {
        return { id: uuidv4(), email };
      });
      userData.notificationEmails = notificationEmails;
      state.user = userData;
      state.error = "";
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.loading = false;
      state.user = {};
      state.error = action.error.message;
    });
    // UPDATE user data
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
      let user = action.payload;
      let emails = user.notificationEmails.map((email) => {
        return { id: uuidv4(), email };
      });
      user.notificationEmails = emails;
      state.user = user;
      state.error = "";
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // RENEW token
    builder.addCase(userRenewToken.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    // RESET user password
    builder.addCase(userResetPassword.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userResetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
      state.error = "";
    });
    builder.addCase(userResetPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // VERIFY user
    builder.addCase(userVerifyEmail.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userVerifyEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload;
      state.error = "";
    });
    builder.addCase(userVerifyEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // GENERATE email verify link
    builder.addCase(userSocialEmailVerifyLink.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userSocialEmailVerifyLink.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(userSocialEmailVerifyLink.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // VERIFY social email
    builder.addCase(userSocialEmailVerify.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userSocialEmailVerify.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload;
      state.error = "";
    });
    builder.addCase(userSocialEmailVerify.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
