import React, { useEffect, useState } from "react";
import produce from "immer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import _ from "lodash";
import toast from "react-hot-toast";

import Chart from "../Chart/Chart";
import Backdrop from "../Backdrop/Backdrop";
import ResourceFilter from "../ResourceFilter/ResourceFilter";
import { formatMs } from "../../utils/formatMs";

import "./ResourceView.scss";
// Import Swiper styles
import "swiper/scss";
import "swiper/scss/navigation";

const ResourceView = (props) => {
  const [isSSLDown, setIsSSLDown] = useState(false);
  const [isSSLExpired, setIsSSLExpired] = useState(false);
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState(null);
  const [regionDataMap, setRegionDataMap] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [sslData, setSslData] = useState({
    expiryDate: "",
    expiryTime: { days: "", hours: "", minutes: "" },
    lastUpdated: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    region: "",
    status: "",
    itemsPerPage: { label: "15" },
    timeToLoadMin: "",
    timeToLoadMax: "",
  });

  const regions = {
    westus: "West US",
    eastus: "East US",
    westeurope: "West Europe",
    southeastasia: "Southeast Asia",
  };

  // RESET filterOptions whenever the filterDate changes
  useEffect(() => {
    setTotalPages(Math.ceil(tableData?.length / itemsPerPage));
    setCurrentPage(1);
    setFilterOptions({
      region: "",
      status: "",
      itemsPerPage: { label: "15" },
      timeToLoadMin: "",
      timeToLoadMax: "",
    });
  }, [props.filterDate]);

  useEffect(() => {
    if (props.resource.length === 0) {
      return;
    }
    // Format the data array so that every entry is a {time, index} object
    if (props.resourceType !== "ssl-certificate") {
      let resourceCopy = _.cloneDeep(props.resource);
      resourceCopy.forEach((region) => {
        let arr = region.data.map((time, index) => {
          return { time, index };
        });
        region.data = _.reverse(arr);
      });

      // Create tableData array where every item is {time: time, index: index, region: region, length: dataPoints}
      // Create regionDataMap object which contains all regions as keys and their respective data(only success or error data)
      let tData;
      let regionDataObj = {};
      resourceCopy.forEach((region) => {
        tData = [];
        region.data.forEach((data) => {
          if (
            data.time > 0 ||
            (data.time === 0 && isDataError(region.errorMessages, data.index))
          ) {
            tData.push({
              time: data.time,
              index: data.index,
              region: region.region,
              length: region.data.length,
            });
          }
        });
        regionDataObj[regions[region.region]] = tData;
        setRegionDataMap(regionDataObj);
      });
      setTotalPages(Math.ceil(tData?.length / itemsPerPage));
      setTableData(tData);
    }

    // Set the chartData array if the resource type is http or ip
    if (
      props.resourceType === "http-endpoint" ||
      props.resourceType === "ip" ||
      props.resourceType === "telnet"
    ) {
      if (props.resource.length > 0) {
        props.resource?.forEach((region) => {
          let arr = region.data.map((time) => ({ time })); // {time: time}
          setChartData(
            produce((draft) => {
              draft[region.region] = arr;
            })
          );
        });
      } else {
        setChartData({});
      }

      // Calculate SSL expiry date if resource type is SSL
    } else if (props.resourceType === "ssl-certificate") {
      const expiryDate = new Date(
        props.resource?.[0]?.expiryAsOnDate
      ).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      // Calculate SSL expiry in days
      const sslResource = props.resource?.[0];
      const resourceCheckedDate = new Date();
      // Display "Unable to determine" if resource asCheckedOn is null
      if (!sslResource.expiryAsOnDate) {
        setIsSSLDown(true);
      } else {
        const resourceExpiryDate = new Date(sslResource?.expiryAsOnDate);
        const difference = resourceExpiryDate - resourceCheckedDate;
        // if difference is negative, then display "SSL has expired {days} ago"
        if (difference < 0) {
          setIsSSLExpired(true);
        }
        // Calculate Days, Hours and Minutes
        const { days, hours, minutes } = formatMs(difference);

        // Calculate last updated date
        let lastCheckedDate = "";
        if (props.resource?.[0]?.updatedAt) {
          let d1 = new Date(props.resource?.[0]?.asCheckedOn);
          let d2 = new Date(props.resource?.[0]?.updatedAt);
          if (d1 > d2) {
            lastCheckedDate = d1;
          } else {
            lastCheckedDate = d2;
          }
        } else {
          lastCheckedDate = new Date(props.resource?.[0]?.asCheckedOn);
        }
        const lastUpdated = new Date(lastCheckedDate).toLocaleDateString(
          "en-GB",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
          }
        );

        setSslData(
          produce((draft) => {
            draft.expiryDate = expiryDate;
            draft.expiryTime = {
              days: days < 9 ? "0" + days : days,
              hours: hours < 9 ? "0" + hours.toFixed(0) : hours.toFixed(0),
              minutes:
                minutes < 9 ? "0" + minutes.toFixed(0) : minutes.toFixed(0),
            };
            draft.lastUpdated = lastUpdated;
          })
        );
      }
    }
  }, [props.resource, props.resourceType]);

  const closeDialogOnEscape = (e) => {
    // close the filter dialog if the escape key is pressed
    if (e.key === "Escape") {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", closeDialogOnEscape);

    return () => {
      document.removeEventListener("keydown", closeDialogOnEscape);
    };
  }, []);

  // Return true or false depending whether error exists or not
  const isDataError = (errorArr, index) => {
    let found = errorArr.find((el) => el.index === index);
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  const toggleFilterDialog = (e) => {
    e.stopPropagation();
    setShowFilter((prevState) => !prevState);
  };

  const filterInputHandler = (key, value) => {
    setFilterOptions(
      produce((draft) => {
        draft[key] = value;
      })
    );
  };

  // Convert the data index(minutes) to date in user's local time zone
  const calculateDataTime = (minutes, totalDataPoints) => {
    let m = minutes;
    // For 5 minute resource frequency
    if (totalDataPoints === 288) {
      m = minutes * 5;
    }
    let UTC12AMms = new Date().setUTCHours(0, 0, 0);
    let dataMs = m * 60 * 1000;
    let currentMs = UTC12AMms + dataMs;
    let date = new Date(currentMs);
    let str = `${
      !props.filterDate
        ? new Date().toISOString().split("T")[0]
        : props.filterDate
    }, ${date.toLocaleTimeString()}`;
    return str;
  };

  const submitFilterInputHandler = () => {
    try {
      let obj = _.cloneDeep(regionDataMap);
      let data = {};

      if (
        filterOptions.status.label === "Error" &&
        (filterOptions.timeToLoadMax.length > 0 ||
          filterOptions.timeToLoadMin.length > 0)
      ) {
        toast.error(
          "Status cannot be Error if you want to filter by Min and Max time.",
          {
            id: "statusError",
          }
        );
        return;
      }

      // REGION FILTER
      if (filterOptions.region.label?.length > 0) {
        data[filterOptions.region.label] = obj[filterOptions.region.label];
      }

      // STATUS FILTER
      if (filterOptions.status.label === "Success") {
        Object.keys(obj).forEach((region) => {
          let arr = obj[region].filter((d) => d.time > 0);
          data[region] = arr;
        });
      } else if (filterOptions.status.label === "Error") {
        Object.keys(obj).forEach((region) => {
          let arr = obj[region].filter((d) => d.time === 0);
          data[region] = arr;
        });
      }

      // TIME TO LOAD FILTER
      if (
        filterOptions.timeToLoadMin.length > 0 ||
        filterOptions.timeToLoadMax.length > 0
      ) {
        const min = +filterOptions.timeToLoadMin.trim();
        const max = +filterOptions.timeToLoadMax.trim();

        if (max < min) {
          toast.error("Max value cannot be lower than Min value.", {
            id: "maxValue",
          });
          return;
        }

        Object.keys(obj).forEach((region) => {
          let arr = obj[region].filter(
            (d) => d.time >= min && (max > 0 ? d.time <= max : true)
          );
          data[region] = arr;
        });
      }

      // ITEMS PER PAGE FILTER
      if (
        !filterOptions.region &&
        !filterOptions.status &&
        filterOptions.itemsPerPage.label?.length > 0 &&
        filterOptions.timeToLoadMin.length === 0 &&
        filterOptions.timeToLoadMax.length === 0
      ) {
        let arr = [];
        Object.values(obj).forEach((d) => {
          arr.push(...d);
        });
        setTableData(arr);
        setItemsPerPage(+filterOptions.itemsPerPage.label);
        setTotalPages(
          Math.ceil(arr?.length / +filterOptions.itemsPerPage.label)
        );
        setCurrentPage(1);
        setShowFilter((prev) => !prev);
        return;
      }
      setItemsPerPage(+filterOptions.itemsPerPage.label);

      // Update dataTable array
      let arr = [];
      if (filterOptions.region.label?.length > 0) {
        arr = data[filterOptions.region.label];
      } else {
        Object.values(data)?.forEach((d) => {
          if (d) {
            arr.push(...d);
          }
        });
      }
      setCurrentPage(1);
      setTotalPages(
        arr?.length
          ? Math.ceil(arr?.length / +filterOptions.itemsPerPage.label)
          : 0
      );
      setTableData(arr);
      setShowFilter((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };

  const nextPageHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevState) => prevState + 1);
    }
  };

  const previousPageHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevState) => prevState - 1);
    }
  };

  return (
    <div className="resource-view">
      {showFilter && (
        <Backdrop onClick={toggleFilterDialog}>
          <ResourceFilter
            onCloseFilter={toggleFilterDialog}
            onFilterInput={filterInputHandler}
            filterOptions={filterOptions}
            onSubmit={submitFilterInputHandler}
          />
        </Backdrop>
      )}
      <div className="resource-view__details">
        <div className="resource-view__details__type">
          <p>
            <i className="icon-cursor" />
            {props.resourceType}
          </p>
          <p>
            <i className="icon-circles" />
            {props.resource?.[0]?.uri}
          </p>
        </div>
        <div className="resource-view__details__group">
          <p>{props.groupName}</p>
          <span>group</span>
        </div>
      </div>
      {props.resourceType === "ssl-certificate" && (
        <div className="resource-view__ssl">
          {isSSLDown && (
            <h2 className="ssl-down--unable">
              Unable to determine. Kindly update your resource, and make sure
              the website is up.
            </h2>
          )}
          {isSSLExpired && (
            <h2 className="ssl-down" style={{ color: "#F83427" }}>
              SSL certificate expired. <br />
              {Math.abs(sslData.expiryTime.days.substring(1))} day(s){" "}
              {Math.abs(sslData.expiryTime.hours.substring(1))} hour(s){" "}
              {Math.abs(sslData.expiryTime.minutes)} minutes ago
            </h2>
          )}
          {!isSSLDown && !isSSLExpired && (
            <>
              <h2 className="ssl-details">
                <p>
                  <span>Days</span>
                  {sslData.expiryTime.days}
                </p>
                :{" "}
                <p>
                  <span>Hours</span>
                  {sslData.expiryTime.hours}
                </p>
                :{" "}
                <p>
                  <span>Minutes</span>
                  {sslData.expiryTime.minutes}
                </p>
              </h2>
              <h3>
                <p>
                  <span>Expires on</span>
                  {sslData.expiryDate}
                </p>
              </h3>
              <h3>
                <p>
                  <span>Last checked on</span>
                  {sslData.lastUpdated}
                </p>
              </h3>
            </>
          )}
        </div>
      )}
      {props.resourceType !== "ssl-certificate" &&
        props.resource.length > 0 && (
          <div className="resource-view__chart">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              navigation
              modules={[Navigation]}
              className="mySwiper"
            >
              {Object.keys(chartData).map((key, index) => (
                <SwiperSlide key={index}>
                  <div className="resource-view__chart__container">
                    <div className="resource-view__chart__average">
                      <p>
                        {(
                          chartData[key].reduce(
                            (acc, cur) => acc + cur.time,
                            0
                          ) / 1440
                        ).toFixed(6) + "s"}
                      </p>
                      <span>24 hours average</span>
                    </div>
                    <Chart data={chartData[key]} />
                    <div className="resource-view__chart__time">
                      <p>12:00 AM</p>
                      <p>12:00 PM</p>
                      <p>11:59 PM</p>
                    </div>
                    <p className="resource-view__chart__region">
                      {regions[key]}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      {props.resourceType !== "ssl-certificate" && (
        <div className="resource-view__actions">
          <label htmlFor="date">
            <input
              id="date"
              type="date"
              onChange={props.onFilterDate}
              value={
                !props.filterDate
                  ? new Date().toISOString().split("T")[0]
                  : props.filterDate
              }
            />
          </label>
          <button type="button" onClick={toggleFilterDialog}>
            <i className="icon-filter" />
            Filter
          </button>
        </div>
      )}
      {props.resourceType !== "ssl-certificate" &&
        props.resource.length > 0 && (
          <div className="resource-view__table">
            <table>
              <tbody>
                <tr>
                  <th>
                    Checked At <br />
                    (Local Time)
                  </th>
                  <th>Region</th>
                  <th>
                    Time Taken <br />
                    (in Seconds)
                  </th>
                  <th>Status</th>
                </tr>
                {/* TABLE DATA START */}
                {tableData
                  ?.slice(
                    (currentPage - 1) * itemsPerPage,
                    itemsPerPage * currentPage
                  )
                  .map((data, index) => (
                    <tr key={index}>
                      <td>{calculateDataTime(data.index, data.length)}</td>
                      <td>{regions[data.region]}</td>
                      <td>{data.time.toFixed(6)}</td>
                      <td>
                        <i
                          className={`icon-${
                            data.time === 0 ? "cross" : "tick"
                          }`}
                        />
                      </td>
                    </tr>
                  ))}
                {/* TABLE DATA END */}
              </tbody>
            </table>
            <div className="resource-view__table__page-actions">
              <i
                className={`icon-page-left ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                onClick={previousPageHandler}
              />
              <span>{currentPage}</span>
              <i
                className={`icon-page-right ${
                  currentPage === totalPages || totalPages === 0
                    ? "disabled"
                    : ""
                }`}
                onClick={nextPageHandler}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default ResourceView;
