import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { fetchResource } from "../store/features/editResource/editResourceActions";

const RedirectToResource = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resourceId } = useParams();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchResource({ resourceId, token: user.token }))
      .unwrap()
      .then((response) => {
        const groupName = response.groupName;
        const resourceType = response.resourceType;
        const resourceName = response.name;
        const resourceId = response._id;

        navigate(
          `/resource/${groupName}/${resourceType}/${resourceName}/${resourceId}/details`
        );
      });
  }, [dispatch, resourceId, user.token, navigate]);

  return <></>;
};

export default RedirectToResource;
