import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import { userActions } from "./store/features/user/userSlice";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };
    const errInterceptor = (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(userActions.logout());
        toast.error(
          "You have been logged out due to inactivity. Please login to continue."
        );
      }
      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    setIsSet(true);

    return () => instance.interceptors.response.eject(interceptor);
  }, []);

  return isSet && children;
};

export default instance;

export { AxiosInterceptor };
