import React from "react";
import { PropTypes } from "prop-types";

import "./Checkbox.scss";

const Checkbox = (props) => {
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        name={props.name}
        checked={props.checked}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <span className="checkbox__label">{props.label}</span>
    </label>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Checkbox;
