import { createSlice } from "@reduxjs/toolkit";
import { fetchResourceDetails } from "./resourceDetailsActions";

const initialState = {
  loading: false,
  resource: [],
  error: "",
};

const resourceDetailsSlice = createSlice({
  name: "resourceDetails",
  initialState: initialState,
  extraReducers(builder) {
    builder.addCase(fetchResourceDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchResourceDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.resource = action.payload;
      state.error = "";
    });
    builder.addCase(fetchResourceDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default resourceDetailsSlice.reducer;
