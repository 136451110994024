import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// width="100%" height={300}

const Chart = (props) => {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <AreaChart
          data={props.data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="name" tickLine={false} />
          <YAxis tick={{ stroke: "#fff" }} tickLine={false} unit={"s"} />
          {/* <Tooltip cursor={false} /> */}
          <CartesianGrid vertical={false} />
          <Area
            type="monotone"
            dataKey="time"
            stroke="#FF9147"
            fill="#FF9147"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
