import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import produce from "immer";

import "./CreateGroup.scss";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import { createGroup } from "../../store/features/groupList/groupListActions";
import Spinner from "../Spinner/Spinner";

const CreateGroup = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const groupList = useSelector((state) => state.groupList);
  const [createGroupForm, setCreateGroupForm] = useState({
    groupName: {
      value: "",
      error: false,
    },
    groupDescription: {
      value: "",
      error: false,
    },
  });

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        props.onHideCreateDialog();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const formChangeHandler = (key, value) => {
    if (key === "groupName") {
      if (value.trim().length !== 0) {
        setCreateGroupForm(
          produce((draft) => {
            draft[key].error = false;
          })
        );
      }
    }
    setCreateGroupForm(
      produce((draft) => {
        draft[key].value = value;
      })
    );
  };

  const createGroupHandler = (event) => {
    event.preventDefault();
    if (createGroupForm.groupName.value.trim().length === 0) {
      setCreateGroupForm(
        produce((draft) => {
          draft.groupName.error = true;
        })
      );
      return;
    }
    const data = {
      group: {
        name: createGroupForm.groupName.value,
        description: createGroupForm.groupDescription.value,
      },
      token: user.token,
    };
    // dispatch(createGroup(data));
    props.onCreateGroup(data);
    props.onHideCreateDialog();
  };

  return (
    <>
      {groupList.loading && <Spinner />}
      <form
        onSubmit={createGroupHandler}
        onClick={(event) => event.stopPropagation()}
        className="create-group-form"
      >
        <div className="close-create-group">
          <i className="icon-close" onClick={props.onHideCreateDialog} />
        </div>
        <div className="create-group-form__name">
          <InputText
            type="text"
            label="Group Name"
            placeholder="Enter group name"
            value={createGroupForm.groupName.value}
            onChange={(event) =>
              formChangeHandler("groupName", event.target.value)
            }
            error={createGroupForm.groupName.error}
            caption={
              createGroupForm.groupName.error ? "Group name is required" : ""
            }
          />
        </div>
        <div className="create-group-form__description">
          <span>Description</span>
          <textarea
            value={createGroupForm.groupDescription.value}
            onChange={(event) =>
              formChangeHandler("groupDescription", event.target.value)
            }
            placeholder="Enter group description"
          />
        </div>
        <Button
          type="submit"
          label="Create Group"
          btnSize="medium"
          mode="primary"
          styles={{ margin: "0 auto" }}
        />
      </form>
    </>
  );
};

export default CreateGroup;
