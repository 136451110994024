import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./UserLogin.scss";

import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import logo from "../../assets/logo.png";
import AuthButton from "../AuthButton/AuthButton";

const UserLogin = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const verified = searchParams.get("verified");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);

    if (e.target.value.trim().length > 0) {
      props.setIsValidEmail(true);
    }
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    if (e.target.value.trim().length > 0) {
      props.setIsValidPassword(true);
    }
  };

  const signupHandler = () => {
    navigate("/signup");
    return;
  };

  return (
    <div className="user-login__container">
      <div className="user-login">
        <div className="user-login__logo">
          <img src={logo} alt="Awakish Logo" />
        </div>
        <div className="user-login__heading">
          <h1>Sign In</h1>
        </div>
        {verified === "true" && (
          <p className="email-verified">Email Verified!</p>
        )}
        <form
          onSubmit={(e) => props.onSignIn(e, email, password)}
          className="user-login__form"
        >
          <div className="user-login__form__input">
            <InputText
              label="Email Address"
              placeholder="Enter email"
              onChange={emailChangeHandler}
              error={!props.isValidEmail}
              caption={props.isValidEmail ? "" : "Enter a valid email"}
              value={email}
            />
          </div>
          <div className="user-login__form__input">
            <InputText
              label="Password"
              type="password"
              placeholder="Enter password"
              onChange={passwordChangeHandler}
              error={!props.isValidPassword}
              caption={props.isValidPassword ? "" : "Enter a valid password"}
              value={password}
            />
          </div>
          <button
            onClick={() => props.onForgotPassword(email)}
            className="user-login__form__forgot-btn"
            type="button"
          >
            Forgot Password?
          </button>
          <Button
            type="submit"
            label="Sign In"
            mode="primary"
            btnSize="large"
            styles={{ maxWidth: "100%" }}
          />
        </form>
        <div className="user-login__divider">
          <div>
            <hr />
          </div>
          <span>or</span>
          <div>
            <hr />
          </div>
        </div>
        <div className="user-login__oauth">
          <AuthButton
            label="Google"
            borderHoverProviderColor="google"
            icon={<i className="icon-google" style={{ cursor: "pointer" }} />}
            onClick={() => props.onThirdPartyLogin("google")}
          />
          <AuthButton
            label="Facebook"
            borderHoverProviderColor="facebook"
            icon={<i className="icon-facebook" style={{ cursor: "pointer" }} />}
            onClick={() => props.onThirdPartyLogin("facebook")}
          />
          <AuthButton
            label="Github"
            borderHoverProviderColor="github"
            icon={<i className="icon-github" style={{ cursor: "pointer" }} />}
            onClick={() => props.onThirdPartyLogin("github")}
          />
        </div>
        <div className="question-btn" style={{ marginTop: "3rem" }}>
          <p>
            Don't have an account?{" "}
            <button onClick={signupHandler}>Sign Up</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
