import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import axios from "../../../axios";
import { generateErrorMsg } from "../../../utils/errorMsgGenerator";

export const fetchResourceDetails = createAsyncThunk(
  "resourceDetails/fetchResourceDetails",
  async (data) => {
    try {
      let date;
      if (!data.date) {
        date = new Date();
        let dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        date = dateString;
      } else {
        date = data.date;
      }

      let response;
      switch (data.resourceType) {
        case "http-endpoint":
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/resource/id/${data.resourceId}/pinger/results?date=${date}`,
            {
              headers: {
                Authorization: `Bearer ${data.token}`,
              },
            }
          );
          break;
        case "ssl-certificate":
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/resource/id/${data.resourceId}/ssl/results`,
            {
              headers: {
                Authorization: `Bearer ${data.token}`,
              },
            }
          );
          break;
        case "ip":
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/resource/id/${data.resourceId}/ip/results?date=${date}`,
            { headers: { Authorization: `Bearer ${data.token}` } }
          );
          break;
        case "telnet":
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/resource/id/${data.resourceId}/telnet/results`,
            { headers: { Authorization: `Bearer ${data.token}` } }
          );
          break;
        default:
          break;
      }
      return response.data.data.results;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage);
      }
      throw errorMessage;
    }
  }
);
