import React from "react";

import InputDropdown from "../InputDropdown/InputDropdown";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";

import "./ResourceFilter.scss";

const ResourceFilter = (props) => {
  return (
    <div className="resource-filter" onClick={(e) => e.stopPropagation()}>
      <div className="resource-filter__option">
        <InputDropdown
          label="Filter by Region"
          items={[
            { label: "East US", value: "eastus" },
            { label: "West US", value: "westus" },
            { label: "West Europe", value: "westeurope" },
            { label: "Southeast Asia", value: "southeastasia" },
          ]}
          selectedItem={props.filterOptions.region.label}
          onClick={(item) =>
            props.onFilterInput("region", {
              label: item.label,
              value: item.value,
            })
          }
        />
      </div>
      <div className="resource-filter__option">
        <InputDropdown
          label="Filter by Status"
          items={[{ label: "Success" }, { label: "Error" }]}
          selectedItem={props.filterOptions.status.label}
          onClick={(item) =>
            props.onFilterInput("status", { label: item.label })
          }
        />
      </div>
      <div className="resource-filter__option">
        <InputDropdown
          label="Items per page"
          items={[{ label: "15" }, { label: "30" }]}
          selectedItem={props.filterOptions.itemsPerPage.label}
          onClick={(item) =>
            props.onFilterInput("itemsPerPage", { label: item.label })
          }
        />
      </div>
      <div className="resource-filter__option" style={{ marginBottom: 0 }}>
        <span className="resource-filter__option__label">
          Time to load (in seconds)
        </span>
        <div className="loading-time">
          <InputText
            placeholder="Min"
            value={props.filterOptions.timeToLoadMin}
            onChange={(e) =>
              props.onFilterInput("timeToLoadMin", e.target.value)
            }
          />
          <InputText
            placeholder="Max"
            value={props.filterOptions.timeToLoadMax}
            onChange={(e) =>
              props.onFilterInput("timeToLoadMax", e.target.value)
            }
          />
        </div>
      </div>
      <div className="resource-filter__option__actions">
        <div className="btn-container">
          <Button
            label="Discard"
            mode="outline"
            btnSize="large"
            onClick={props.onCloseFilter}
            styles={{ margin: "0 auto" }}
          />
        </div>
        <div className="btn-container">
          <Button
            label="Apply"
            mode="primary"
            btnSize="large"
            onClick={props.onSubmit}
            styles={{ margin: "0 auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResourceFilter;
