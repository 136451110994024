import React, { useState, useEffect } from "react";

import "./UserForgotPassword.scss";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import logo from "../../assets/logo.png";

const UserForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (props.paramsEmail) {
      setEmail(props.paramsEmail);
    }
  }, [props.paramsEmail]);

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);

    if (e.target.value.trim().length > 0) {
      props.setIsValidEmail(true);
    }
  };

  return (
    <div className="user-forgot__container">
      <div className="user-forgot">
        <div className="user-forgot__logo">
          <img src={logo} alt="Awakish Logo" />
        </div>
        {props.isMailSent && (
          <p className="email-sent">
            Password reset link sent! Kindly check your email to proceed
            further. In case you don't see an email, make sure to check the
            <span style={{ color: "#ff6600" }}>Spam/Junk</span> folder as well.
          </p>
        )}
        <form
          onSubmit={(e) => props.onSendMail(e, email)}
          className="user-forgot__form"
        >
          <div className="user-forgot__form__input">
            <InputText
              label="Email Address"
              placeholder="Enter email"
              onChange={emailChangeHandler}
              value={email}
              error={!props.isValidEmail}
              caption={props.isValidEmail ? "" : "Enter valid email"}
            />
          </div>
          <Button
            type="submit"
            label="Send mail"
            mode="primary"
            btnSize="large"
            styles={{ maxWidth: "100%" }}
          />
        </form>
      </div>
    </div>
  );
};

export default UserForgotPassword;
