import React from "react";

import "./Backdrop.scss";

const Backdrop = (props) => {
  return (
    <div onClick={props.onClick} className="backdrop">
      {props.children}
    </div>
  );
};

export default Backdrop;
