import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axios";
import toast from "react-hot-toast";
import { generateErrorMsg } from "../../../utils/errorMsgGenerator";

// FETCH group details
export const fetchGroupDetails = createAsyncThunk(
  "groupDetails/fetchGroupDetails",
  async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/group/${data.groupId}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage);
      }
      throw errorMessage;
    }
  }
);

// FETCH group resources
export const fetchGroupResources = createAsyncThunk(
  "groupDetails/fetchGroupResources",
  async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/resources/${data.groupId}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response.data.data.results;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage);
      }
      throw errorMessage;
    }
  }
);

// UPDATE group
export const updateGroupDetails = createAsyncThunk(
  "groupDetails/updateGroupDetails",
  async (data) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/group/${data.groupId}`,
        data.group,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      // Update localstorage group if the same group is being updated
      let localGroup = JSON.parse(localStorage.getItem("lastAccessedGroup"));
      if (localGroup.id === response.data.data.result._id) {
        localGroup.label = response.data.data.result.name;
        localStorage.setItem("lastAccessedGroup", JSON.stringify(localGroup));
      }
      toast.success("Group updated successfully.");
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage, {
          id: "groupDetails",
        });
      }
      throw errorMessage;
    }
  }
);

// TOGGLE resource
export const toggleGroupResource = createAsyncThunk(
  "groupDetails/toggleGroupResource",
  async (data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/resource/isEnabled/${data.resourceId}/${data.status}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      toast.success(`Resource ${data.status ? "Enabled" : "Disabled"}`);
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage, {
          id: "toggle",
        });
      }
      throw errorMessage;
    }
  }
);

// DELETE resource
export const deleteGroupResource = createAsyncThunk(
  "groupDetails/deleteGroupResource",
  async (data) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/resource/${data.resourceId}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      toast.success("Resource deleted successfully!");
      return data.resourceId;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage, { id: "delete" });
      }
      throw errorMessage;
    }
  }
);
