import React from "react";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";

import "./Group.scss";

const Group = (props) => {
  const navigate = useNavigate();

  const openGroupHandler = () => {
    navigate(`/groups/${props.groupId}`);
  };

  return (
    <>
      <div className="group" onClick={openGroupHandler}>
        <div>
          <h2 className="group__name">{props.groupName}</h2>
          <p className="group__resources">{props.resources.length} Resources</p>
        </div>
        <div className="group__delete">
          <i
            className="icon-trash"
            onClick={(e) => props.onGroupDelete(e, props.groupId)}
          />
        </div>
      </div>
    </>
  );
};

Group.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  resources: PropTypes.array,
};

export default Group;
