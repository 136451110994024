import { createSlice } from "@reduxjs/toolkit";
import {
  deleteGroupResource,
  fetchGroupDetails,
  fetchGroupResources,
  toggleGroupResource,
  updateGroupDetails,
} from "./groupDetailsActions";

const initialState = {
  loading: false,
  groupDetails: {
    _id: "",
    name: "",
    description: "",
    resources: [],
  },
  error: "",
};

const groupDetailsSlice = createSlice({
  name: "groupDetails",
  initialState: initialState,
  // FETCH group details {name, description, id}
  extraReducers(builder) {
    builder.addCase(fetchGroupDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchGroupDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.groupDetails._id = action.payload._id;
      state.groupDetails.name = action.payload.name;
      state.groupDetails.description = action.payload.description;
      state.error = "";
    });
    builder.addCase(fetchGroupDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // FETCH group resources (isEnabled, regions etc)
    builder.addCase(fetchGroupResources.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchGroupResources.fulfilled, (state, action) => {
      state.loading = false;
      state.groupDetails.resources = action.payload;
      state.error = "";
    });
    builder.addCase(fetchGroupResources.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // UPDATE group
    builder.addCase(updateGroupDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateGroupDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(updateGroupDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // TOGGLE group resource
    builder.addCase(toggleGroupResource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(toggleGroupResource.fulfilled, (state, action) => {
      state.loading = false;
      // Add logic to change resource status in redux based on response
      state.error = "";
    });
    builder.addCase(toggleGroupResource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // DELETE group resource
    builder.addCase(deleteGroupResource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteGroupResource.fulfilled, (state, action) => {
      state.loading = false;
      let resources = state.groupDetails.resources.filter(
        (resource) => resource._id !== action.payload
      );
      state.groupDetails.resources = resources;
      state.error = "";
    });
    builder.addCase(deleteGroupResource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default groupDetailsSlice.reducer;
