import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./EditGroup.scss";
import InputText from "../InputText/InputText";
import ResourceDetails from "../ResourceDetails/ResourceDetails";
import Button from "../Button/Button";
import toast from "react-hot-toast";

const EditGroup = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName(props.group.name);
    setDescription(props.group.description);
  }, [props.group.name, props.group.description]);

  const nameChangeHandler = (event) => {
    setName(event.target.value);
    if (event.target.value.trim().length > 0) {
      setNameError(false);
    }
  };

  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };

  const addResourceHandler = () => {
    navigate(`/resource/new?groupId=${props.group._id}`);
  };

  const editResourceHandler = (id) => {
    navigate(`/resource/${id}/edit`);
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    // Group Name validation
    if (name.trim().length === 0) {
      setNameError(true);
      toast.error("Kindly enter a valid Group title.", {
        id: "invalidGroup",
      });
      return;
    }
    props.onFormSubmit(name, description);
  };

  return (
    <div className="edit-group">
      <form onSubmit={formSubmitHandler}>
        <div className="edit-group__details">
          <InputText
            type="text"
            label="Group Title"
            value={name || ""}
            onChange={nameChangeHandler}
            placeholder="Group name"
            error={nameError}
            caption={nameError ? "Group title is required" : ""}
          />
          <div className="edit-group__description">
            <span>Group Description</span>
            <textarea
              value={description || ""}
              onChange={descriptionChangeHandler}
              placeholder="Group description"
            />
          </div>
        </div>
        <div className="edit-group__create">
          <button type="button" onClick={addResourceHandler}>
            Add New Resource
          </button>
          <p>
            {props.group.resources && props.group.resources.length} Resources
          </p>
        </div>
        <div className="edit-group__resources">
          {props.group.resources &&
            props.group.resources.map((resource) => (
              <ResourceDetails
                key={resource._id}
                resource={resource}
                onResourceEdit={editResourceHandler}
                onResourceDelete={() => props.onDelete(resource._id)}
                onResourceStatusToggle={props.toggleResourceStatus}
                groupName={props.group.name}
              />
            ))}
        </div>
        <div className="edit-group__submit">
          <Button label="Save" btnSize="large" mode="primary" type="submit" />
          <div className="question-btn edit-group__group-delete">
            <p>
              Want to delete this group?{" "}
              <button type="button" onClick={props.onDeleteGroup}>
                Delete Group
              </button>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditGroup;
