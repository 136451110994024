import React from "react";
import { PropTypes } from "prop-types";

import "./InputText.scss";

const InputText = (props) => {
  return (
    <div className="input-text">
      {props.label && <span className="input-text__label">{props.label}</span>}
      <div className="input-text__box">
        {props.icon && (
          <span
            className={`input-text__box__icon ${
              props.disabled ? "input-text__box__icon--disabled" : ""
            }`}
          >
            {props.icon}
          </span>
        )}
        <input
          type={props.type}
          placeholder={props.placeholder}
          className={`input-text__box__input ${
            props.icon ? "input-text__box__input--icon-padding" : ""
          } ${props.error ? "input-text__box__input--error" : ""}`}
          disabled={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          readOnly={props.readOnly}
          defaultValue={props.defaultValue}
          autoFocus={props.autoFocus}
          onFocus={(e) => e.target.select()}
          // {...props}
        />
      </div>
      {props.caption && (
        <span
          className={`input-text__caption ${
            props.error ? "input-text__caption--error" : ""
          }`}
        >
          {props.caption}
        </span>
      )}
    </div>
  );
};

InputText.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  // onChange: PropTypes.func.isRequired,
  caption: PropTypes.string,
  // value: PropTypes.string,
};

InputText.defaultProps = {
  type: "text",
};

export default InputText;
