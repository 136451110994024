function decodeJwtToken(jwtToken) {
  try {
    // token consists in 3 parts and we need the middle one.
    // split the token with '.'
    const jwtTokenSplit = jwtToken.split(".");
    const jwtPayloadPart = jwtTokenSplit[1];
    // base64 decode the payload part to get the stored info
    const decodedPayload = JSON.parse(window.atob(jwtPayloadPart));
    return decodedPayload;
  } catch (e) {
    return null;
  }
}
export { decodeJwtToken };
