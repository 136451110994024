export const formatMs = (ms) => {
  const daysInMs = 1 * 24 * 60 * 60 * 1000;
  const hourInMs = 1 * 60 * 60 * 1000;
  const minInMs = 1 * 60 * 1000;

  const days = Math.floor(ms / daysInMs);
  const hours = Math.floor((ms - days * daysInMs) / hourInMs);
  const minutes = Math.floor(
    (ms - days * daysInMs - hours * hourInMs) / minInMs
  );

  return {
    days,
    hours,
    minutes,
  };
};
