import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Notification.scss";
import NotificationDetails from "../NotificationDetails/NotificationDetails";
import Backdrop from "../Backdrop/Backdrop";
import { deleteSingleNotification } from "../../store/features/notifications/notificationsActions";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

const REGIONS = {
  eastus: "East US",
  westus: "West US",
  europe: "Europe",
  southeastasia: "Southeast Asia",
};

const Notification = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [showNotification, setShowNotification] = useState(false);
  const [date, setDate] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let userTimezone = Intl.DateTimeFormat().resolvedOptions().timezone;
    let notificationDate = new Date(
      props.notification.createdAt
    ).toLocaleString("en-US", { timeZone: userTimezone });
    setDate(notificationDate);
  }, [props.notification.createdAt]);

  const openNotificationHandler = () => {
    setShowNotification(true);
  };

  const closeNotificationHandler = () => {
    setShowNotification(false);
  };

  const deleteNotificationHandler = async () => {
    await dispatch(
      deleteSingleNotification({
        token: user.token,
        id: props.notification._id,
      })
    ).unwrap();
    props.isCustomSelected ? props.onFetchCustom() : props.onFetchAll();
    setIsDeleting(false);
  };

  const openDeleteModal = (e) => {
    e.stopPropagation();
    setIsDeleting(true);
  };

  return (
    <>
      {showNotification && (
        <Backdrop onClick={closeNotificationHandler}>
          <NotificationDetails
            details={props.notification.details}
            date={date}
          />
        </Backdrop>
      )}
      {isDeleting && (
        <Backdrop>
          <ConfirmationModal
            heading="Delete Notification"
            title="Are you sure you want to delete this notification?"
            showOkBtn={true}
            showCancelBtn={true}
            onCancel={() => setIsDeleting(false)}
            onConfirm={deleteNotificationHandler}
          />
        </Backdrop>
      )}
      <div onClick={openNotificationHandler} className="notification">
        <p className="notification__heading">
          <span className="notification__heading__resource-name">
            {props.notification.message.split("could be down")[0]}
          </span>
          <span>could be down</span>
        </p>
        <p className="notification__region">
          {REGIONS[props.notification.region]}
        </p>
        <div className="notification__date">
          <p>{date}</p>
          <i onClick={openDeleteModal} className="icon-trash" />
        </div>
      </div>
    </>
  );
};

export default Notification;
