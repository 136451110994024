import React from "react";
import { useNavigate } from "react-router-dom";

import "./GroupDetail.scss";
import Button from "../Button/Button";
import ResourceStatus from "../ResourceStatus/ResourceStatus";

const GroupDetail = (props) => {
  const navigate = useNavigate();

  const addResourceHandler = () => {
    navigate(`/resource/new?groupId=${props.groupId}`);
  };

  const editGroupHandler = () => {
    navigate(`/groups/${props.groupId}/edit`);
  };

  return (
    <div className="group-detail">
      <div className="group-detail__content">
        <h2 className="group-detail__content__heading">{props.name}</h2>
        <p className="group-detail__content__count">
          {props.resources && props.resources.length} Resources
        </p>
      </div>
      <p className="group-detail__content__description">{props.description}</p>
      <div className="group-detail__actions">
        <Button
          type="button"
          mode="outline"
          btnSize="large"
          label="Edit Group Details"
          styles={{ fontSize: "1.6rem", maxWidth: "100%" }}
          onClick={editGroupHandler}
        />
        <Button
          type="button"
          mode="primary"
          btnSize="large"
          label="Add New Resource"
          styles={{ fontSize: "1.6rem", maxWidth: "100%" }}
          onClick={addResourceHandler}
        />
      </div>
      {props.downCount > 0 && (
        <div className="group-detail__downcount">
          <p>{props.downCount} Resource(s) could be down</p>
        </div>
      )}
      <div className="group-detail__resources">
        {props.resources &&
          props.resources.map((resource) => (
            <ResourceStatus
              key={resource._id}
              resource={resource}
              groupName={props.name}
            />
          ))}
      </div>
    </div>
  );
};

export default GroupDetail;
