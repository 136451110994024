import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./UserVerifyEmail.scss";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import logo from "../../assets/logo.png";
import {
  userExchangeTempToken,
  userNewToOld,
  userSocialEmailVerify,
  userVerifyEmail,
} from "../../store/features/user/userActions";
import Spinner from "../Spinner/Spinner";
import { decodeJwtToken } from "../../utils/decodeJwt";
import { userActions } from "../../store/features/user/userSlice";

const UserVerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { tempToken } = useParams();
  const user = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.token) {
      const decodedJwt = decodeJwtToken(user.token);
      if (decodedJwt.newUser) {
        setLoading(true);
        window.location.href = "http://www.awakish.com/accounts-thank-you/";
        // dispatch(userNewToOld({ token: user.token }));
      } else {
        navigate("/dashboard");
      }
    }
  }, [user.token, navigate, dispatch]);

  useEffect(() => {
    const socialLogin = searchParams.get("socialLogin");
    if (socialLogin) {
      dispatch(userSocialEmailVerify({ token: tempToken }))
        .unwrap()
        .then((res) => {
          dispatch(userActions.syncLogin(res));
        })
        .catch((error) => {
          navigate("/");
        });
    } else {
      dispatch(userExchangeTempToken({ tempToken }))
        .unwrap()
        .then((response) => {
          dispatch(userVerifyEmail({ token: response }))
            .unwrap()
            .then((res) => {
              dispatch(userActions.syncLogin(response));
            });
        })
        .catch((error) => {
          navigate("/");
        });
    }
  }, [dispatch, tempToken, searchParams, navigate]);

  return (
    <>
      {user.loading || loading ? (
        <Spinner />
      ) : (
        <div className="user-verify__container">
          <div className="user-verify">
            <div className="user-verify__logo">
              <img src={logo} alt="Awakish Logo" />
            </div>
            <form onSubmit={() => {}} className="user-verify__form">
              <div className="user-verify__form__input">
                <InputText
                  label="Email Address"
                  placeholder="Enter email"
                  value={email}
                  readOnly={true}
                  disabled={true}
                />
              </div>
              <Button
                type="submit"
                label="Send mail"
                mode="primary"
                btnSize="large"
                styles={{ maxWidth: "100%" }}
              />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UserVerifyEmail;
