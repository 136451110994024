import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../components/Header/Header";
import Wrapper from "../components/Wrapper/Wrapper";
import ResourceView from "../components/ResourceView/ResourceView";
import { fetchResourceDetails } from "../store/features/resourceDetails/resourceDetailsActions";
import Spinner from "../components/Spinner/Spinner";

const ResourceAnalytics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const resourceDetails = useSelector((state) => state.resourceDetails);
  const { resourceType, resourceName, resourceId, groupName } = useParams();
  const [result, setResult] = useState([]);
  const [filterDate, setFilterDate] = useState("");

  useEffect(() => {
    dispatch(
      fetchResourceDetails({
        resourceId,
        token: user.token,
        resourceType,
        date: filterDate,
      })
    )
      .unwrap()
      .then((response) => {
        setResult(response);
      });
  }, [user.token, dispatch, resourceId, resourceType, filterDate]);

  const editResourceHandler = () => {
    navigate(`/resource/${resourceId}/edit`);
    return;
  };

  return (
    <>
      <Header
        label={resourceName}
        showBtn={true}
        btn={
          <i
            style={{ cursor: "pointer" }}
            className="icon-Icon-1"
            onClick={editResourceHandler}
          />
        }
      />
      <Wrapper showBorder={true} fillBackground={true}>
        {resourceDetails.loading ? (
          <Spinner />
        ) : (
          <ResourceView
            resource={result}
            resourceType={resourceType}
            groupName={groupName}
            onFilterDate={(e) => setFilterDate(e.target.value)}
            filterDate={filterDate}
          />
        )}
      </Wrapper>
    </>
  );
};

export default ResourceAnalytics;
