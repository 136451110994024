import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import produce from "immer";

import "../UserProfile/UserProfile.scss";

import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";
import Wrapper from "../Wrapper/Wrapper";
import logo from "../../assets/logo.png";
import toast from "react-hot-toast";
import { userSocialEmailVerifyLink } from "../../store/features/user/userActions";
import { userActions } from "../../store/features/user/userSlice";
import { decodeJwtToken } from "../../utils/decodeJwt";

const UpdateProfileAfterLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userRdx = useSelector((state) => state.user);

  const [verifyLinkSent, setVerifyLinkSent] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
  });
  const [fieldError, setFieldError] = useState({
    firstName: false,
    lastName: false,
  });

  useEffect(() => {
    if (userRdx.token) {
      navigate("/dashboard");
    }
  }, [userRdx.token, navigate]);

  useEffect(() => {
    setUser(
      produce((draft) => {
        draft.firstName = searchParams.get("firstName") || "";
        draft.lastName = searchParams.get("lastName") || "";
      })
    );
  }, [searchParams, searchParams.firstName, searchParams.lastName]);

  const userChangeHandler = (key, value) => {
    setUser(
      produce((draft) => {
        draft[key] = value;
      })
    );
    if (value.trim().length !== 0) {
      setFieldError(
        produce((draft) => {
          draft[key] = false;
        })
      );
    }
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    // Form validation
    let error = false;
    const fields = [
      { label: "firstName", value: user.firstName },
      { label: "lastName", value: user.lastName },
      { label: "email", value: user.email },
    ];
    fields.forEach((field) => {
      if (
        (field && !field.value) ||
        (typeof field.value === "string" && field.value.trim().length === 0)
      ) {
        error = true;
        setFieldError(
          produce((draft) => {
            draft[field.label] = true;
          })
        );
      }
    });

    if (error) {
      toast.error("One or more fields have empty values. Kindly re-check.", {
        id: "duplicate",
      });
      return;
    }

    dispatch(
      userSocialEmailVerifyLink({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        socialId: searchParams.get("socialId"),
        companyName: user.companyName,
      })
    )
      .unwrap()
      .then((response) => {
        dispatch(userActions.syncLogin(response.token));
        const decodedToken = decodeJwtToken(response.token);
        if (decodedToken.newUser) {
          window.location.href = "https://www.awakish.com/accounts-thank-you/";
        }
      });
  };

  return (
    <Wrapper>
      {userRdx.loading && <Spinner />}
      <div className="user-profile">
        <div className="user-profile__logo">
          <img src={logo} alt="Awakish Logo" />
        </div>
        {!user.loading && verifyLinkSent ? (
          <h3 style={{ textAlign: "center" }}>
            Kindly click on the verification link sent to your email address (
            {user.email}) to login. In case you don't see an email, make sure to
            check the <span style={{ color: "#ff6600" }}>Spam/Junk</span> folder
            as well.
          </h3>
        ) : (
          <form spellCheck="false" onSubmit={formSubmitHandler}>
            <div className="user-profile__form">
              <h3>Your Info</h3>
              <InputText
                placeholder="First name"
                value={user.firstName || ""}
                label="First Name"
                type="text"
                onChange={(event) => {
                  userChangeHandler("firstName", event.target.value);
                }}
                error={fieldError.firstName}
                caption={fieldError.firstName ? "First name is required" : ""}
              />
              <InputText
                placeholder="Last name"
                value={user.lastName || ""}
                label="Last Name"
                type="text"
                onChange={(event) => {
                  userChangeHandler("lastName", event.target.value);
                }}
                error={fieldError.lastName}
                caption={fieldError.lastName ? "Last name is required" : ""}
              />
              <InputText
                placeholder="Company name"
                value={user.companyName || ""}
                label="Company Name"
                type="text"
                onChange={(event) => {
                  userChangeHandler("companyName", event.target.value);
                }}
                error={fieldError.companyName}
                caption={
                  fieldError.companyName ? "Company name is required" : ""
                }
              />
              <InputText
                placeholder="Email address"
                label="Email"
                type="email"
                value={user.email || ""}
                onChange={(event) => {
                  userChangeHandler("email", event.target.value);
                }}
                error={fieldError.email}
                caption={fieldError.email ? "Email is required" : ""}
              />
            </div>
            <div className="user-profile__submit">
              <Button
                label="Update Profile"
                btnSize="large"
                mode="primary"
                type="submit"
                onClick={() => {}}
                styles={{ margin: "0 auto", maxWidth: "443px" }}
              />
            </div>
          </form>
        )}
      </div>
    </Wrapper>
  );
};

export default UpdateProfileAfterLogin;
