import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./UserSignup.scss";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import logo from "../../assets/logo.png";
import AuthButton from "../AuthButton/AuthButton";

const UserSignup = (props) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");

  const firstNameChangeHandler = (e) => {
    setFirstName(e.target.value);

    if (e.target.value.trim().length > 0) {
      props.setIsValidFirstName(true);
    }
  };

  // Remove the last name field as per requirement
  // const lastNameChangeHandler = (e) => {
  //   setLastName(e.target.value);

  //   if (e.target.value.trim().length > 0) {
  //     props.setIsValidLastName(true);
  //   }
  // };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);

    if (e.target.value.trim().length > 0) {
      props.setIsValidEmail(true);
    }
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);

    if (e.target.value.trim().length > 0) {
      props.setIsValidPassword(true);
    }
  };

  // Remove the confirm password field as per requirement
  // const confirmPasswordChangeHandler = (e) => {
  //   setConfirmPassword(e.target.value);

  //   if (e.target.value.trim().length > 0) {
  //     props.setIsValidConfirmPassword(true);
  //   }
  // };

  // const forgotHandler = () => {
  //   navigate("/login/forgot");
  //   return;
  // };

  const signInHandler = () => {
    navigate("/?isRedirected=true");
    return;
  };

  return (
    <div className="user-signup__container">
      <div className="user-signup">
        <div className="user-signup__logo">
          <img src={logo} alt="Awakish Logo" />
        </div>
        <h1 className="user-signup__text">
          Sign up for FREE<span>.</span>
        </h1>
        {props.hasSignedUp && (
          <h3 style={{ textAlign: "center" }}>
            Kindly click on the verification link sent to your email address (
            {email}) to login. In case you don't see an email, make sure to
            check the <span style={{ color: "#ff6600" }}>Spam/Junk</span> folder
            as well.
          </h3>
        )}
        {!props.hasSignedUp && (
          <>
            <form
              onSubmit={(e) =>
                props.onSignUp(
                  e,
                  firstName,
                  // lastName,
                  email,
                  password
                  // confirmPassword
                )
              }
              className="user-signup__form"
            >
              <div className="user-signup__form__input">
                <InputText
                  label="Full Name"
                  placeholder="Enter full name"
                  onChange={firstNameChangeHandler}
                  error={!props.isValidFirstName}
                  caption={
                    props.isValidFirstName ? "" : "Enter a valid full name"
                  }
                  value={firstName}
                />
              </div>
              {/* Remove the last name field as per requirement */}
              {/* <div className="user-signup__form__input">
                <InputText
                  label="Last Name"
                  placeholder="Enter last name"
                  onChange={lastNameChangeHandler}
                  error={!props.isValidLastName}
                  caption={
                    props.isValidLastName ? "" : "Enter a valid lastname"
                  }
                  value={lastName}
                />
              </div> */}
              <div className="user-signup__form__input">
                <InputText
                  label="Email Address"
                  placeholder="Enter email address"
                  onChange={emailChangeHandler}
                  error={!props.isValidEmail}
                  caption={props.isValidEmail ? "" : "Enter a valid email"}
                  value={email}
                />
              </div>
              <div className="user-signup__form__input">
                <InputText
                  label="Password"
                  type="password"
                  placeholder="Enter password"
                  onChange={passwordChangeHandler}
                  error={!props.isValidPassword}
                  caption={
                    props.isValidPassword
                      ? ""
                      : "Minimum length of password should be 8 characters"
                  }
                  value={password}
                />
              </div>
              {/* Remove the confirm password field as per requirement */}
              {/* <div className="user-signup__form__input">
                <InputText
                  label="Confirm Password"
                  type="password"
                  placeholder="Enter confirm password"
                  onChange={confirmPasswordChangeHandler}
                  error={!props.isValidConfirmPassword}
                  caption={
                    props.isValidConfirmPassword ? "" : "Passwords do not match"
                  }
                  value={confirmPassword}
                />
              </div> */}
              <Button
                type="submit"
                label="Sign up"
                mode="primary"
                btnSize="large"
                styles={{ maxWidth: "100%" }}
              />
            </form>
            {/* OAuth Login Start */}
            <div className="user-signup__divider">
              <div>
                <hr />
              </div>
              <span>or</span>
              <div>
                <hr />
              </div>
            </div>
            <div className="user-signup__oauth">
              <AuthButton
                label="Google"
                borderHoverProviderColor="google"
                icon={
                  <i className="icon-google" style={{ cursor: "pointer" }} />
                }
                onClick={() => props.onThirdPartyLogin("google")}
              />
              <AuthButton
                label="Facebook"
                borderHoverProviderColor="facebook"
                icon={
                  <i className="icon-facebook" style={{ cursor: "pointer" }} />
                }
                onClick={() => props.onThirdPartyLogin("facebook")}
              />
              <AuthButton
                label="Github"
                borderHoverProviderColor="github"
                icon={
                  <i className="icon-github" style={{ cursor: "pointer" }} />
                }
                onClick={() => props.onThirdPartyLogin("github")}
              />
            </div>
            {/* OAuth Login End */}
            <div className="question-btn" style={{ marginTop: "3rem" }}>
              <p>
                Already have an account?{" "}
                <button onClick={signInHandler}>Sign In</button>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserSignup;
