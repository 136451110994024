import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";

import UserResetPassword from "../components/UserResetPassword/UserResetPassword";
import {
  userExchangeTempToken,
  userResetPassword,
} from "../store/features/user/userActions";

const Reset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tempToken } = useParams();
  const user = useSelector((state) => state.user);

  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);

  useEffect(() => {
    if (user.error) {
      toast.error(user.error);
    }
  }, [user.error]);

  const savePasswordHandler = (e, password, confirmPassword) => {
    e.preventDefault();

    // Password Validation
    if (password.trim().length === 0 || password.trim().length < 8) {
      setIsValidPassword(false);
      return;
    }

    // Confirm Password Validation
    if (password !== confirmPassword) {
      setIsValidConfirmPassword(false);
      return;
    }
    if (user.token) {
      dispatch(userResetPassword({ token: user.token, newPassword: password }))
        .unwrap()
        .then((res) => {
          navigate("/dashboard");
        });
    } else {
      dispatch(userExchangeTempToken({ tempToken: tempToken }))
        .unwrap()
        .then((response) => {
          dispatch(
            userResetPassword({ token: response, newPassword: password })
          )
            .unwrap()
            .then((res) => {
              navigate("/dashboard");
            });
        });
    }
  };

  return (
    <>
      {user.loading && <Spinner />}
      <UserResetPassword
        onSavePassword={savePasswordHandler}
        isValidPassword={isValidPassword}
        isValidConfirmPassword={isValidConfirmPassword}
        setIsValidPassword={setIsValidPassword}
        setIsValidConfirmPassword={setIsValidConfirmPassword}
      />
    </>
  );
};

export default Reset;
