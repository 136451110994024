import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import {
  fetchUserData,
  updateUser,
} from "../../store/features/user/userActions";
import InputText from "../InputText/InputText";
// import InputMobile from "../InputMobile/InputMobile";
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";

import "./UserProfile.scss";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRdx = useSelector((state) => state.user);

  const [user, setUser] = useState({});
  const [fieldError, setFieldError] = useState({
    firstName: false,
    lastName: false,
    companyName: false,
    email: false,
  });
  const [notificationError, setNotificationError] = useState([]);

  useEffect(() => {
    if (!userRdx.user.email) {
      dispatch(fetchUserData({ token: userRdx.token }));
    } else {
      setUser(userRdx.user);
    }
  }, [userRdx.token, dispatch, userRdx.user]);

  const userChangeHandler = (key, value) => {
    setUser(
      produce((draft) => {
        draft[key] = value;
      })
    );
    if (value.trim().length !== 0) {
      setFieldError(
        produce((draft) => {
          draft[key] = false;
        })
      );
    }
  };

  const changeNotificationEmailHandler = (index, value) => {
    setUser(
      produce((draft) => {
        draft.notificationEmails[index] = {
          ...draft.notificationEmails[index],
          email: value,
        };
      })
    );
    if (value.trim().length !== 0) {
      let idx = notificationError.indexOf(index);
      if (idx !== -1) {
        setNotificationError(
          produce((draft) => {
            draft.splice(idx, 1);
          })
        );
      }
    }
  };

  const deleteNotificationEmailHandler = (index) => {
    setUser(
      produce((draft) => {
        draft.notificationEmails.splice(index, 1);
      })
    );
    // Error logic for showing error if email inputBox is blank
    let errorIndexes = [...notificationError];
    errorIndexes = errorIndexes.map((el) => {
      if (el > index) {
        return (el = el - 1);
      } else {
        return el;
      }
    });
    let idx = notificationError.indexOf(index);
    if (idx !== -1) {
      errorIndexes.splice(idx, 1);
    }
    setNotificationError(errorIndexes);
  };

  const addNotificationEmailHandler = () => {
    setUser(
      produce((draft) => {
        draft.notificationEmails.push({
          id: uuidv4(),
          email: "",
        });
      })
    );
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    // Form validation
    let error = false;
    const fields = [
      { label: "firstName", value: user.firstName },
      { label: "lastName", value: user.lastName },
      { label: "email", value: user.email },
    ];
    fields.forEach((field) => {
      if (
        (field && !field.value) ||
        (typeof field.value === "string" && field.value.trim().length === 0)
      ) {
        error = true;
        setFieldError(
          produce((draft) => {
            draft[field.label] = true;
          })
        );
      }
    });

    // Notification Validation
    // Store the index of email input where the value is blank
    let blankEmailIndexes = [];
    user.notificationEmails.forEach((notification, index) => {
      if (notification.email.trim().length === 0) {
        blankEmailIndexes.push(index);
      }
    });

    if (blankEmailIndexes.length > 0) {
      error = true;
      setNotificationError(blankEmailIndexes);
    }

    if (error) {
      toast.error("One or more fields have empty values. Kindly re-check.", {
        id: "duplicate",
      });
      return;
    }

    const notificationEmails = user.notificationEmails.map((email) => {
      return email.email;
    });

    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      companyName: user.companyName,
      email: user.email,
      notificationEmails: notificationEmails,
    };

    dispatch(updateUser({ userData: data, token: userRdx.token }));
  };

  const forgotHandler = () => {
    navigate(`/reset-password/${userRdx.token}`);
  };

  return (
    <>
      {userRdx.loading && <Spinner />}
      <div className="user-profile">
        {/* <div className="user-profile__subscription">
          <h1>
            You are on <span>Subscription Plan</span>{" "}
          </h1>
          <p>
            With limit of <span>30</span> monitored resources
          </p>
          <Button
            label="Upgrade Now"
            type="button"
            btnSize="large"
            mode="primary"
            styles={{ margin: "0 auto", maxWidth: "443px" }}
          />
        </div> */}
        <form spellCheck="false">
          <div className="user-profile__form">
            <h3>Your Info</h3>
            <InputText
              placeholder="First name"
              value={user.firstName || ""}
              label="First Name"
              type="text"
              onChange={(event) =>
                userChangeHandler("firstName", event.target.value)
              }
              error={fieldError.firstName}
              caption={fieldError.firstName ? "First name is required" : ""}
            />
            <InputText
              placeholder="Last name"
              value={user.lastName || ""}
              label="Last Name"
              type="text"
              onChange={(event) =>
                userChangeHandler("lastName", event.target.value)
              }
              error={fieldError.lastName}
              caption={fieldError.lastName ? "Last name is required" : ""}
            />
            <InputText
              placeholder="Company name"
              value={user.companyName || ""}
              label="Company Name"
              type="text"
              onChange={(event) =>
                userChangeHandler("companyName", event.target.value)
              }
              error={fieldError.companyName}
              caption={fieldError.companyName ? "Company name is required" : ""}
            />
            <InputText
              placeholder="Email"
              label="Email"
              type="email"
              value={user.email || ""}
              onChange={(event) =>
                userChangeHandler("email", event.target.value)
              }
              error={fieldError.email}
              caption={fieldError.email ? "Email is required" : ""}
            />
          </div>
          <div className="user-profile__notifications">
            <h3>Send Notifications to: </h3>
            {user.notificationEmails &&
              user.notificationEmails.map((email, index) => (
                <div
                  key={email.id}
                  className="user-profile__notifications__input"
                >
                  <InputText
                    autoFocus={email.email === ""}
                    defaultValue={email.email}
                    placeholder="Email Address"
                    onChange={(event) =>
                      changeNotificationEmailHandler(index, event.target.value)
                    }
                    error={notificationError.includes(index)}
                    caption={
                      notificationError.includes(index)
                        ? "Email is required"
                        : ""
                    }
                  />
                  <i
                    className="icon-trash-2"
                    onClick={() => deleteNotificationEmailHandler(index)}
                  />
                </div>
              ))}
            <button
              onClick={addNotificationEmailHandler}
              type="button"
              className="user-profile__notifications__create"
            >
              Add Another Email
            </button>
          </div>
          <div className="user-profile__submit">
            <Button
              label="Update Profile"
              btnSize="large"
              mode="primary"
              type="submit"
              onClick={formSubmitHandler}
              styles={{ margin: "0 auto", maxWidth: "443px" }}
            />
          </div>
          <div className="question-btn">
            <p>
              Want to change your password?{" "}
              <button onClick={forgotHandler}>Reset Password</button>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserProfile;
