import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./GroupResources.scss";
import Button from "../Button/Button";
import ResourceStatus from "../ResourceStatus/ResourceStatus";

const GroupResources = (props) => {
  const navigate = useNavigate();
  const [downCount, setDownCount] = useState(0);

  const checkDownCount = useCallback(() => {
    let down = 0;
    props.group.resources?.forEach((resource) => {
      let resourceTemp = 0;
      if (resource.resourceType !== "ssl-certificate") {
        if (resourceTemp > 0) {
          return;
        }
        resource.regions?.forEach((region) => {
          if (resourceTemp > 0) {
            return;
          }
          if (region.data[region.latestDataIndex] === 0) {
            down++;
            resourceTemp++;
          }
        });
      }
    });
    return down;
  }, [props.group.resources]);

  useEffect(() => {
    setDownCount(checkDownCount());
  }, [checkDownCount]);

  const addResourceHandler = () => {
    navigate(`/resource/new?groupId=${props.group.groupId}`);
  };

  const editGroupHandler = () => {
    navigate(`/groups/${props.group.groupId}/edit`);
  };

  return (
    <div className="group-resources">
      <div className="group-resources__header">
        <div className="group-resources__header__name">
          <h2>
            {props.group.groupName}
            <i onClick={editGroupHandler} className="icon-Icon-1" />
          </h2>
          {downCount > 0 && <p>{downCount} resource(s) needs your attention</p>}
        </div>
        <Button
          label="Add to group"
          btnSize="small"
          mode="primary"
          onClick={addResourceHandler}
          type="button"
          styles={{ width: "130px" }}
        />
      </div>
      <hr />
      <div className="group-resources__list">
        {props.group.resources.map((resource) => (
          <ResourceStatus
            key={resource._id}
            resource={resource}
            groupName={props.group.groupName}
          />
        ))}
      </div>
    </div>
  );
};

export default GroupResources;
