import React, { useState, useEffect } from "react";
import validator from "validator";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import toast from "react-hot-toast";

import UserForgotPassword from "../components/UserForgotPassword/UserForgotPassword";
import { userForgotPassword } from "../store/features/user/userActions";
import Spinner from "../components/Spinner/Spinner";

const Forgot = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const paramsEmail = searchParams.get("email");
  const [loading, setLoading] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const sendMailHandler = useCallback(
    async (e, email) => {
      try {
        if (e) {
          e.preventDefault();
        }

        // Email Validation
        if (!validator.isEmail(email)) {
          setIsValidEmail(false);
          return;
        }
        setLoading(true);
        await dispatch(userForgotPassword({ email })).unwrap();
        setIsMailSent(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message, { id: "forgot" });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (paramsEmail) {
      sendMailHandler(null, paramsEmail);
    }
  }, [paramsEmail, sendMailHandler]);

  return (
    <>
      {loading && <Spinner />}
      <UserForgotPassword
        onSendMail={sendMailHandler}
        isValidEmail={isValidEmail}
        setIsValidEmail={setIsValidEmail}
        paramsEmail={paramsEmail}
        isMailSent={isMailSent}
      />
    </>
  );
};

export default Forgot;
