import React from "react";

import "./Wrapper.scss";

const Wrapper = (props) => {
  return (
    <div
      className={`wrapper ${props.fillBackground ? "wrapper--bg" : ""} ${
        props.showBorder ? "wrapper--border" : ""
      }`}
    >
      {props.children}
    </div>
  );
};

Wrapper.defaultProps = {
  fillBackground: false,
  showBorder: false,
};

export default Wrapper;
