import React from "react";
import { PropTypes } from "prop-types";

import "./AuthButton.scss";

const AuthButton = (props) => {
  return (
    <div className="auth-button">
      <button
        type="button"
        onClick={props.onClick}
        className={`btn btn--${props.borderHoverProviderColor}`}
      >
        {props.label}
      </button>
      {props.icon && <div className="auth-button__icon">{props.icon}</div>}
    </div>
  );
};

AuthButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

export default AuthButton;
