import React from "react";

import "./ConfirmationModal.scss";
import Button from "../Button/Button";

const ConfirmationModal = (props) => {
  return (
    <div className="confirmation-modal" onClick={(e) => e.stopPropagation()}>
      <div className="confirmation-modal__heading">
        <h2>{props.heading}</h2>
      </div>
      <div className="confirmation-modal__title">
        <p>{props.title}</p>
      </div>
      {props.withJsx && (
        <div className="confirmation-modal__body">{props.jsx}</div>
      )}
      <div className="confirmation-modal__actions">
        {props.showOkBtn && (
          <Button
            type="button"
            mode="primary"
            btnSize="large"
            label="Ok"
            styles={{ width: "10rem" }}
            onClick={props.onConfirm}
          />
        )}
        {props.showCancelBtn && (
          <Button
            type="button"
            mode="outline"
            btnSize="large"
            label="Cancel"
            styles={{ width: "10rem" }}
            onClick={props.onCancel}
          />
        )}
      </div>
    </div>
  );
};

export default ConfirmationModal;
