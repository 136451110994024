import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axios";
import toast from "react-hot-toast";
import { generateErrorMsg } from "../../../utils/errorMsgGenerator";

// FETCH all groups with its resources
/*
  {
    groupId: 1234
    groupName: demo
    resources: [...]
  }
*/
export const fetchGroups = createAsyncThunk(
  "group/fetchGroups",
  async (data) => {
    try {
      let demoExpiryDate = new Date();
      demoExpiryDate.setDate(demoExpiryDate.getDate() + 20);
      demoExpiryDate = new Date(demoExpiryDate).toISOString();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/resource/user/results`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response.data.data.results;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage);
      }
      throw errorMessage;
    }
  }
);

// CREATE a new group
export const createGroup = createAsyncThunk(
  "group/createGroup",
  async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/group`,
        data.group,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      toast.success("Group created successfully.");
      localStorage.setItem(
        "lastAccessedGroup",
        JSON.stringify({
          id: response.data.data.result._id,
          label: response.data.data.result.name,
        })
      );
      return response.data.data.result;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage, {
          id: "createGroup",
        });
      }
      throw errorMessage;
    }
  }
);

// DELETE a group
export const deleteGroup = createAsyncThunk(
  "group/deleteGroup",
  async (data) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/group/${data.groupId}`,
        { headers: { Authorization: `Bearer ${data.token}` } }
      );
      toast.success("Group deleted successfully.");
      return data.groupId;
    } catch (error) {
      let errorMessage = generateErrorMsg(error);
      if (error.response.status !== 401) {
        toast.error(errorMessage, { id: "deleteGroup" });
      }
      throw errorMessage;
    }
  }
);
