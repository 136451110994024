import React from "react";
import { PropTypes } from "prop-types";

import "./Button.scss";

const Button = (props) => {
  return (
    <div className="button">
      <button
        name={props.name}
        style={props.styles}
        type={props.type}
        className={`btn btn--${props.mode} btn--${props.btnSize} ${
          props.btnClass && props.btnClass
        } ${
          props.iconPosition === "left"
            ? "btn__icon--left"
            : props.iconPosition === "right"
            ? "btn__icon--right"
            : ""
        }`}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.label}
      </button>
      {props.icon && (
        <div
          className={`button__icon button__icon--${props.mode} ${
            props.disabled ? "button__icon--disabled" : ""
          } ${
            props.iconPosition
              ? props.iconPosition === "left"
                ? "button__icon--left"
                : "button__icon--right"
              : ""
          }`}
        >
          {props.icon}
        </div>
      )}
    </div>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  iconPosition: PropTypes.string,
  mode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  btnClass: PropTypes.string,
  btnSize: PropTypes.string.isRequired,
};

Button.defaultProps = {
  type: "button",
};

export default Button;
