import React from "react";
import { useNavigate } from "react-router-dom";

import "./ResourceDetails.scss";
import SwitchToggle from "../SwitchToggle/SwitchToggle";

const ResourceDetails = (props) => {
  const navigate = useNavigate();

  const regions = {
    westus: "West US",
    eastus: "East US",
    westeurope: "West Europe",
    southeastasia: "Southeast Asia",
  };

  return (
    <div className="resource">
      <div className="resource-details">
        <div className="resource-details__header">
          <p className="resource-details__header__name">
            {props.resource.name}
          </p>
          <p className="resource-details__header__type">
            <i className="icon-cursor" />
            {props.resource.resourceType}
          </p>
        </div>
        <div className="resource-details__uri">
          <i className="icon-circles" />
          {props.resource.uri}
        </div>
        <div className="resource-details__regions">
          {props.resource.options.map((region) => (
            <div key={region._id} className="">
              {props.resource.resourceType === "ssl-certificate" ? (
                <p>
                  Checks SSL Certification{" "}
                  <span>
                    {region.frequency === "a-day" ? "Everyday" : "Every Week"}
                  </span>
                </p>
              ) : (
                <p>
                  Checks <span>{regions[region.region]}</span> every{" "}
                  <span>{region.frequency.replace("-", " ")}</span>
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="resource-actions">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="resource-actions__edit">
            <i
              className="icon-Icon-1"
              onClick={() => props.onResourceEdit(props.resource._id)}
            />
            <i
              className="icon-trash-2"
              onClick={() => props.onResourceDelete(props.resource._id)}
            />
            <i
              className="icon-go"
              onClick={() =>
                navigate(
                  `/resource/${props.groupName}/${props.resource.resourceType}/${props.resource.name}/${props.resource._id}/details`
                )
              }
            />
          </div>
          <SwitchToggle
            active={props.resource.isEnabled}
            onClick={(status) =>
              props.onResourceStatusToggle(status, props.resource._id)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ResourceDetails;
