import React from "react";

const Down = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.84"
        y="1.17997"
        width="16.32"
        height="15.64"
        rx="7.82"
        fill="#F83427"
      />
      <path
        d="M10.3599 9.33999L12.3999 9.33999L8.9999 12.74L5.9399 9.33999L7.9799 9.33999L7.9799 5.25999L10.3599 5.25999L10.3599 9.33999Z"
        fill="#303437"
        stroke="#303437"
        strokeWidth="0.68"
      />
      <rect
        x="0.84"
        y="1.17997"
        width="16.32"
        height="15.64"
        rx="7.82"
        stroke="#F83427"
        strokeWidth="0.68"
      />
    </svg>
  );
};

export default Down;
