import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "../../assets/logo.png";
import MenuContext from "../../context/MenuContext";

import "./Navigation.scss";

const Navigation = (props) => {
  const user = useSelector((state) => state.user);
  const menu = useContext(MenuContext);

  return (
    <div
      className={`navigation ${menu.isExpanded ? "navigation--expanded" : ""}`}
    >
      <div className="navigation__logo">
        {!menu.isExpanded && (
          <span>
            <i className="icon-right" onClick={menu.toggleMenu} />
          </span>
        )}
        {menu.isExpanded && (
          <i className="icon-close cross" onClick={menu.toggleMenu} />
        )}
        {menu.isExpanded && <img src={logo} alt="Logo" />}
        {menu.isExpanded && (
          <span>
            <i className="icon-left close" onClick={menu.toggleMenu} />
          </span>
        )}
      </div>
      <ul
        className={`navigation__list ${
          menu.isExpanded ? "navigation__list--expanded" : ""
        }`}
      >
        <li>
          <NavLink
            to="/dashboard"
            style={{ position: "relative" }}
            onClick={menu.isExpanded ? menu.toggleMenu : null}
            className={({ isActive }) =>
              isActive
                ? "navigation__list__link--active"
                : "navigation__list__link"
            }
          >
            <i className="icon-dashboard" style={{ fontSize: "2.2rem" }} />
            {menu.isExpanded && (
              <span
                className="navigation__list__link__text align-menu-items"
                style={
                  menu.isExpanded
                    ? {
                        marginLeft: "2rem",
                      }
                    : {}
                }
              >
                Dashboard
              </span>
            )}
          </NavLink>
        </li>
        {user.token && (
          <li>
            <NavLink
              to="/groups"
              style={{ position: "relative" }}
              onClick={menu.isExpanded ? menu.toggleMenu : null}
              className={({ isActive }) =>
                isActive
                  ? "navigation__list__link--active"
                  : "navigation__list__link"
              }
            >
              <i
                className="icon-folder"
                style={{ fontSize: "1.8rem", marginLeft: "2px" }}
              />
              {menu.isExpanded && (
                <span
                  className="navigation__list__link__text align-menu-items"
                  style={menu.isExpanded ? { marginLeft: "2rem" } : {}}
                >
                  Groups
                </span>
              )}
            </NavLink>
          </li>
        )}
        {user.token && (
          <li>
            <NavLink
              to="/notifications"
              style={{ position: "relative" }}
              onClick={menu.isExpanded ? menu.toggleMenu : null}
              className={({ isActive }) =>
                isActive
                  ? "navigation__list__link--active"
                  : "navigation__list__link"
              }
            >
              <i className="icon-bell" />
              {menu.isExpanded && (
                <span
                  className="navigation__list__link__text align-menu-items"
                  style={menu.isExpanded ? { marginLeft: "2rem" } : {}}
                >
                  Notifications
                </span>
              )}
            </NavLink>
          </li>
        )}
        {user.token && (
          <li>
            <NavLink
              to="/profile"
              style={{ position: "relative" }}
              onClick={menu.isExpanded ? menu.toggleMenu : null}
              className={({ isActive }) =>
                isActive
                  ? "navigation__list__link--active"
                  : "navigation__list__link"
              }
            >
              <i className="icon-profile" />
              {menu.isExpanded && (
                <span
                  className="navigation__list__link__text align-menu-items"
                  style={menu.isExpanded ? { marginLeft: "2rem" } : {}}
                >
                  Profile
                </span>
              )}
            </NavLink>
          </li>
        )}
        {user.token && (
          <li>
            <button className="logout-btn" onClick={props.onShowModal}>
              <i
                className="icon-log-out"
                style={{ display: "block", marginTop: "-2px" }}
              />
              {menu.isExpanded && (
                <span
                  className="navigation__list__link__text align-menu-items"
                  style={menu.isExpanded ? { marginLeft: "2rem" } : {}}
                >
                  Logout
                </span>
              )}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Navigation;
