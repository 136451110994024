import React from "react";

const DownArrow = () => {
  return (
    <svg viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L7 7L13 1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        // stroke="#fff"
      />
    </svg>
  );
};

export default DownArrow;
