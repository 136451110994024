import React from "react";

const Up = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.84"
        y="1.17997"
        width="16.32"
        height="15.64"
        rx="7.82"
        fill="#23C16B"
      />
      <path
        d="M7.97994 8.66001H5.93994L9.33994 5.26001L12.3999 8.66001H10.3599V12.74H7.97994V8.66001Z"
        fill="#303437"
        stroke="#303437"
        strokeWidth="0.68"
      />
      <rect
        x="0.84"
        y="1.17997"
        width="16.32"
        height="15.64"
        rx="7.82"
        stroke="#23C16B"
        strokeWidth="0.68"
      />
    </svg>
  );
};

export default Up;
