import React, { useState, useEffect } from "react";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserSignup from "../components/UserSignup/UserSignup";
import { userLogin, userSignup } from "../store/features/user/userActions";
import Spinner from "../components/Spinner/Spinner";
import { decodeJwtToken } from "../utils/decodeJwt";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  // const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  // const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);

  useEffect(() => {
    if (user.token) {
      navigate("/dashboard");
    }
  }, [user.token, navigate]);

  const signupHandler = async (
    e,
    firstName,
    // lastName,
    email,
    password
    // confirmPassword
  ) => {
    e.preventDefault();

    // Firstname Validation
    if (firstName.trim().length === 0 || firstName.trim().length === 1) {
      setIsValidFirstName(false);
      return;
    }

    // Remove the last name field as per requirement
    // // Lastname Validation
    // if (lastName.trim().length === 0 || lastName.trim().length === 1) {
    //   setIsValidLastName(false);
    //   return;
    // }

    // Email Validation
    if (!validator.isEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    // Password Validation
    if (password.trim().length === 0 || password.trim().length < 8) {
      setIsValidPassword(false);
      return;
    }

    // Remove the confirm password field as per requirement
    // // Confirm Password Validation
    // if (password !== confirmPassword) {
    //   setIsValidConfirmPassword(false);
    //   return;
    // }

    // Send user details to api
    try {
      setLoading(true);
      await dispatch(userSignup({ firstName, email, password })).unwrap();

      localStorage.setItem("hasSignedUp", 1);

      /**
       * No email verification flow START
       */
      const jwtToken = await dispatch(userLogin({ email, password })).unwrap();
      const decodedToken = decodeJwtToken(jwtToken);
      if (decodedToken.newUser) {
        window.location.href = "https://www.awakish.com/accounts-thank-you/";
      }
      /**
       * No email verification flow END
       */

      setLoading(false);
    } catch (error) {
      // setHasSignedUp(false);
      setLoading(false);
    }
  };

  const thirdPartyLogin = (providerName) => {
    let url = `${process.env.REACT_APP_API_URL}/auth/${providerName}`;
    window.location.href = url;
  };

  return (
    <>
      {loading && <Spinner />}
      <UserSignup
        hasSignedUp={hasSignedUp}
        isValidFirstName={isValidFirstName}
        // isValidLastName={isValidLastName}
        isValidEmail={isValidEmail}
        isValidPassword={isValidPassword}
        // isValidConfirmPassword={isValidConfirmPassword}
        onSignUp={signupHandler}
        onThirdPartyLogin={thirdPartyLogin}
        setIsValidFirstName={setIsValidFirstName}
        // setIsValidLastName={setIsValidLastName}
        setIsValidEmail={setIsValidEmail}
        setIsValidPassword={setIsValidPassword}
        // setIsValidConfirmPassword={setIsValidConfirmPassword}
      />
    </>
  );
};

export default Signup;
